import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99967 4.1665C8.15873 4.1665 6.66634 5.65889 6.66634 7.49984C6.66634 8.38717 7.01306 9.19353 7.5784 9.79082C6.04059 10.6436 4.99967 12.2835 4.99967 14.1665C4.99967 14.5079 5.03401 14.8421 5.09968 15.1656C5.17852 15.554 5.52002 15.8332 5.91636 15.8332H14.083C14.4793 15.8332 14.8208 15.554 14.8997 15.1656C14.9653 14.8421 14.9997 14.5079 14.9997 14.1665C14.9997 12.2835 13.9588 10.6436 12.4209 9.79082C12.9863 9.19353 13.333 8.38717 13.333 7.49984C13.333 5.65889 11.8406 4.1665 9.99967 4.1665ZM13.8195 8.98198C14.0415 8.53553 14.1663 8.03226 14.1663 7.49984C14.1663 6.96741 14.0415 6.46414 13.8195 6.0177C14.2747 5.40033 15.0071 4.99984 15.833 4.99984C17.2137 4.99984 18.333 6.11913 18.333 7.49984C18.333 8.06261 18.1471 8.58195 17.8332 8.99977C18.6427 9.60792 19.1663 10.5761 19.1663 11.6665V12.4998C19.1663 12.9601 18.7932 13.3332 18.333 13.3332H15.7639C15.5078 11.8068 14.5602 10.515 13.2543 9.79082C13.4689 9.56413 13.6519 9.30732 13.7965 9.0274C13.8085 9.01811 13.8206 9.0089 13.8328 8.99977L13.8195 8.98198ZM6.74507 9.79082C6.53051 9.56413 6.34743 9.30733 6.20286 9.02742C6.19083 9.01812 6.17873 9.0089 6.16657 8.99977L6.17982 8.98197C5.95783 8.53553 5.83301 8.03226 5.83301 7.49984C5.83301 6.96741 5.95784 6.46415 6.17982 6.0177C5.72461 5.40033 4.99224 4.99984 4.16634 4.99984C2.78563 4.99984 1.66634 6.11913 1.66634 7.49984C1.66634 8.06261 1.85229 8.58195 2.16611 8.99977C1.35661 9.60792 0.833008 10.5761 0.833008 11.6665C0.833008 12.0546 0.899684 12.4289 1.0229 12.7775C1.14059 13.1105 1.45542 13.3332 1.8086 13.3332H4.23547C4.49154 11.8068 5.43913 10.515 6.74507 9.79082Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
