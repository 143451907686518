import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7916 6.36027C11.1397 5.12529 7.12061 4.46978 3.36025 7.54336C3.07949 7.77285 2.91663 8.11631 2.91663 8.47893V23.4584C2.91663 23.9251 3.18548 24.3502 3.60725 24.5502C4.02901 24.7502 4.52825 24.6893 4.88966 24.3939C8.19639 21.6911 11.8185 22.8097 13.7916 24.0855V6.36027ZM16.2083 6.36027V24.0855C18.1814 22.8097 21.8035 21.6911 25.1103 24.3939C25.4717 24.6893 25.9709 24.7502 26.3927 24.5502C26.8144 24.3502 27.0833 23.9251 27.0833 23.4584V8.47893C27.0833 8.11631 26.9204 7.77285 26.6397 7.54336C22.8793 4.46978 18.8602 5.12529 16.2083 6.36027Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
