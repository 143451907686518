import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.3754 3.00037H4.12513C3.50374 3.00037 3 3.50401 3 4.12529V19.8742C3 20.4955 3.50374 20.9992 4.12513 20.9992H6.3754C6.99679 20.9992 7.50053 20.4955 7.50053 19.8742V4.12529C7.50053 3.50401 6.99679 3.00037 6.3754 3.00037Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8759 3.00049H8.62562C8.00423 3.00049 7.50049 3.50413 7.50049 4.12541V19.8744C7.50049 20.4956 8.00423 20.9993 8.62562 20.9993H10.8759C11.4973 20.9993 12.001 20.4956 12.001 19.8744V4.12541C12.001 3.50413 11.4973 3.00049 10.8759 3.00049Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 7.5003H7.50053M7.50053 16.4997H12.0011M13.1262 8.62522L17.6267 7.5003M15.3765 16.4997L19.7903 15.3973M12.9045 3.63056L15.3618 3.03435C15.9941 2.88248 16.6366 3.24808 16.8042 3.85779L20.9616 18.952C21.035 19.2284 21.0025 19.5221 20.8705 19.7758C20.7386 20.0294 20.5167 20.2247 20.2483 20.3233L20.0986 20.3694L17.6413 20.9657C17.009 21.1175 16.3666 20.7519 16.1989 20.1422L12.0416 5.04796C11.9682 4.77162 12.0007 4.47786 12.1326 4.22422C12.2646 3.97057 12.4865 3.77531 12.7549 3.67668L12.9045 3.63056Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
