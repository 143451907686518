import { SVGProps } from 'react';

function SvgComponent(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2254 9.33709C17.5915 9.7032 17.5915 10.2968 17.2254 10.6629L10.6629 17.2254C10.2968 17.5915 9.7032 17.5915 9.33709 17.2254L2.77459 10.6629C2.5915 10.4798 2.49997 10.2399 2.5 9.99989V5.3125C2.5 3.7592 3.7592 2.5 5.3125 2.5H10.0003C10.2401 2.50007 10.4799 2.59159 10.6629 2.77459L17.2254 9.33709ZM5.3125 6.25C5.83027 6.25 6.25 5.83027 6.25 5.3125C6.25 4.79473 5.83027 4.375 5.3125 4.375C4.79473 4.375 4.375 4.79473 4.375 5.3125C4.375 5.83027 4.79473 6.25 5.3125 6.25Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
