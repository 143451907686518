import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.361 5.523H3.088a5.51 5.51 0 013.704-2.89 8.924 8.924 0 00-1.43 2.89zm-.225 1H2.7c-.13.47-.2.965-.2 1.477 0 .512.07 1.007.2 1.477h2.436a9.036 9.036 0 010-2.954zm1.016 2.954a8.03 8.03 0 010-2.954h3.696a8.032 8.032 0 010 2.954H6.152zm-.79 1H3.087a5.51 5.51 0 003.704 2.89 8.926 8.926 0 01-1.43-2.89zm3.846 2.89a5.51 5.51 0 003.704-2.89H10.64a8.926 8.926 0 01-1.431 2.89zm.385-2.89A7.94 7.94 0 018 13.27a7.94 7.94 0 01-1.593-2.793h3.186zm1.27-1H13.3c.13-.47.2-.965.2-1.477 0-.512-.07-1.007-.2-1.477h-2.436a9.038 9.038 0 010 2.954zm-.224-3.954h2.273a5.51 5.51 0 00-3.704-2.89 8.925 8.925 0 011.43 2.89zm-1.046 0H6.407A7.938 7.938 0 018 2.73a7.938 7.938 0 011.593 2.793zM8 1.5a6.5 6.5 0 100 13A6.493 6.493 0 0014.5 8 6.493 6.493 0 008 1.5z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
