import { SVGProps } from 'react';

function SvgComponent(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 0.000488281C5.37328 0.000488281 0 5.37272 0 12C0 18.6273 5.37275 23.9995 12 23.9995C18.6278 23.9995 24 18.6273 24 12C24 5.37272 18.6278 0.000488281 12 0.000488281ZM12 3.58848C14.1927 3.58848 15.9696 5.36587 15.9696 7.55756C15.9696 9.74979 14.1927 11.5267 12 11.5267C9.80831 11.5267 8.03145 9.74979 8.03145 7.55756C8.03145 5.36587 9.80831 3.58848 12 3.58848ZM11.9974 20.8622C9.81042 20.8622 7.80743 20.0657 6.2625 18.7474C5.88615 18.4264 5.66898 17.9557 5.66898 17.4618C5.66898 15.239 7.46798 13.4601 9.69129 13.4601H14.3098C16.5336 13.4601 18.3257 15.239 18.3257 17.4618C18.3257 17.9563 18.1096 18.4259 17.7328 18.7469C16.1884 20.0657 14.1848 20.8622 11.9974 20.8622Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
