import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66667 2.66671C6.29848 2.66671 6 2.96518 6 3.33337L6 3.33553C6.00099 3.64826 6.21732 3.91031 6.50848 3.98116C6.56735 3.99413 6.62235 4.00004 6.66667 4.00004H9.33333C9.37765 4.00004 9.43264 3.99413 9.49151 3.98116C9.78335 3.91015 10 3.64706 10 3.33337C10 2.96518 9.70152 2.66671 9.33333 2.66671H6.66667ZM6.66667 1.33337C5.79585 1.33337 5.05503 1.88991 4.78047 2.66671H4C2.89543 2.66671 2 3.56214 2 4.66671V12.6667C2 13.7713 2.89543 14.6667 4 14.6667H12C13.1046 14.6667 14 13.7713 14 12.6667V4.66671C14 3.56214 13.1046 2.66671 12 2.66671H11.2195C10.945 1.88991 10.2041 1.33337 9.33333 1.33337H6.66667ZM10.4714 7.80478C10.7318 7.54443 10.7318 7.12232 10.4714 6.86197C10.2111 6.60162 9.78895 6.60162 9.5286 6.86197L7.33333 9.05723L6.47141 8.1953C6.21106 7.93495 5.78895 7.93495 5.5286 8.1953C5.26825 8.45565 5.26825 8.87776 5.5286 9.13811L6.86193 10.4714C7.12228 10.7318 7.54439 10.7318 7.80474 10.4714L10.4714 7.80478Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
