import { SVGProps } from 'react';

function SvgComponent(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.9713 11.5703C14.6507 9.46714 14.6546 6.43957 12.979 4.33259C10.7892 1.57658 6.76212 1.39362 4.32581 3.776L3.21647 2.66666C3.15484 2.60503 3.04506 2.64933 3.03158 2.73985L2.42491 6.70149C2.41528 6.7689 2.47113 6.82475 2.53854 6.81513L6.50019 6.20846C6.59071 6.19497 6.63308 6.08327 6.57338 6.02357L5.46596 4.91615C7.18582 3.25022 9.98228 3.33496 11.5885 5.1723C12.9713 6.75157 12.9656 9.16091 11.5789 10.7363C10.1094 12.4061 7.65382 12.6199 5.93204 11.3757C5.61233 11.1446 5.17129 11.1851 4.89396 11.4624C4.54922 11.8071 4.58581 12.383 4.98063 12.67C7.42849 14.4476 10.9645 14.0817 12.9713 11.5703Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
