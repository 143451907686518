import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.5 18.5V5.5C3.5 4.70435 3.81607 3.94129 4.37868 3.37868C4.94129 2.81607 5.70435 2.5 6.5 2.5H17.5V15.5H6.5C4.85 15.5 3.5 16.842 3.5 18.5Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 15.5H6.5C5.70435 15.5 4.94129 15.8161 4.37868 16.3787C3.81607 16.9413 3.5 17.7044 3.5 18.5C3.5 19.2956 3.81607 20.0587 4.37868 20.6213C4.94129 21.1839 5.70435 21.5 6.5 21.5H20.5V3.5M7 18.5H17"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
