import { SVGProps } from 'react';

function SvgComponent(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.99365 10.9999C4.99365 7.13395 8.12766 3.99994 11.9937 3.99994C15.8596 3.99994 18.9937 7.13395 18.9937 10.9999C18.9937 12.9332 18.2112 14.6818 16.9434 15.9497C15.6756 17.2175 13.9269 17.9999 11.9937 17.9999C8.12766 17.9999 4.99365 14.8659 4.99365 10.9999ZM11.9937 1.99994C7.02309 1.99994 2.99365 6.02938 2.99365 10.9999C2.99365 15.9705 7.02309 19.9999 11.9937 19.9999C14.1183 19.9999 16.0721 19.2628 17.6113 18.0318L21.2865 21.707C21.6771 22.0976 22.3102 22.0976 22.7008 21.707C23.0913 21.3165 23.0913 20.6834 22.7008 20.2928L19.0255 16.6176C20.2565 15.0784 20.9937 13.1245 20.9937 10.9999C20.9937 6.02938 16.9642 1.99994 11.9937 1.99994ZM12.9937 7.99994C12.9937 7.44765 12.5459 6.99994 11.9937 6.99994C11.4414 6.99994 10.9937 7.44765 10.9937 7.99994V9.99994H8.99365C8.44137 9.99994 7.99365 10.4477 7.99365 10.9999C7.99365 11.5522 8.44137 11.9999 8.99365 11.9999H10.9937V13.9999C10.9937 14.5522 11.4414 14.9999 11.9937 14.9999C12.5459 14.9999 12.9937 14.5522 12.9937 13.9999V11.9999H14.9937C15.5459 11.9999 15.9937 11.5522 15.9937 10.9999C15.9937 10.4477 15.5459 9.99994 14.9937 9.99994H12.9937V7.99994Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
