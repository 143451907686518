import { SVGProps } from 'react';

function SvgComponent(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.4375 10.9999C4.4375 7.13395 7.57151 3.99994 11.4375 3.99994C15.3035 3.99994 18.4375 7.13395 18.4375 10.9999C18.4375 12.9332 17.6551 14.6818 16.3872 15.9497C15.1194 17.2175 13.3708 17.9999 11.4375 17.9999C7.57151 17.9999 4.4375 14.8659 4.4375 10.9999ZM11.4375 1.99994C6.46694 1.99994 2.4375 6.02938 2.4375 10.9999C2.4375 15.9705 6.46694 19.9999 11.4375 19.9999C13.5621 19.9999 15.5159 19.2628 17.0551 18.0318L20.7304 21.707C21.1209 22.0976 21.7541 22.0976 22.1446 21.707C22.5351 21.3165 22.5351 20.6834 22.1446 20.2928L18.4694 16.6176C19.7003 15.0784 20.4375 13.1245 20.4375 10.9999C20.4375 6.02938 16.4081 1.99994 11.4375 1.99994ZM8.4375 9.99994C7.88522 9.99994 7.4375 10.4477 7.4375 10.9999C7.4375 11.5522 7.88522 11.9999 8.4375 11.9999H11.4375H14.4375C14.9898 11.9999 15.4375 11.5522 15.4375 10.9999C15.4375 10.4477 14.9898 9.99994 14.4375 9.99994H11.4375H8.4375Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
