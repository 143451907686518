import { SVGProps } from 'react';

function SvgComponent(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 1.5V5.66667H5.16667"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.04167 9.833C1.22625 11.5045 1.96725 13.0655 3.14565 14.2652C4.32405 15.465 5.87148 16.2339 7.53941 16.4485C9.20734 16.663 10.899 16.3108 12.3427 15.4484C13.7864 14.5859 14.8983 13.2633 15.5 11.693C16.1016 10.1226 16.158 8.39559 15.6601 6.78931C15.1622 5.18302 14.139 3.79067 12.7546 2.83587C11.3703 1.88106 9.70523 1.41921 8.02686 1.52448C6.34848 1.62975 4.75418 2.29604 3.5 3.41634L1 5.66634"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 4.83301V8.99967L11.8333 10.6663"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
