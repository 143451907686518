import { SVGProps } from 'react';

function SvgComponent(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="32" height="32" rx="16" fill="#E5F0FD" />
      <path
        d="M12 16H12.01M16 16H16.01M20 16H20.01M25 16C25 20.4183 20.9706 24 16 24C14.4607 24 13.0117 23.6565 11.7447 23.0511L7 24L8.39499 20.28C7.51156 19.0423 7 17.5743 7 16C7 11.5817 11.0294 8 16 8C20.9706 8 25 11.5817 25 16Z"
        stroke="#006BEB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
