function SvgComponent() {
  return (
    <svg
      width="264"
      height="205"
      viewBox="0 0 264 205"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.8"
        d="M201.441 171.608C194.755 171.503 188.045 170.865 181.416 170.173C143.754 166.24 103.208 161.828 70.4726 143.343C57.7179 136.163 46.7924 126.565 38.3995 115.167C30.0066 103.77 24.3324 90.8259 21.7424 77.1685C19.2136 63.8633 20.145 48.837 29.7277 38.3673C34.9374 32.6753 42.2509 28.884 49.8691 26.507C60.196 23.2853 71.2877 22.1769 81.9761 20.6556C102.06 17.7976 122.7 16.3754 142.632 20.014C144.418 20.34 146.194 20.7061 147.96 21.1124C176.048 27.5868 200.04 44.4205 219.096 64.3335C235.102 81.0593 248.391 100.692 253.119 122.72C254.914 131.087 255.394 140.049 251.865 148.04C246.805 159.499 234.167 167.05 221.148 169.882C214.67 171.292 208.067 171.711 201.441 171.608Z"
        fill="#ECEDED"
      />
      <path
        d="M94.1716 45.0326C93.3699 45.0325 92.5927 45.3063 91.9704 45.8081C91.3481 46.31 90.9187 47.0091 90.7544 47.7882L70.3604 143.627C70.3181 143.825 70.1214 143.953 69.9212 143.911C69.7209 143.869 69.5929 143.673 69.6352 143.474L90.029 47.6374C90.029 47.6373 90.029 47.6374 90.029 47.6374C90.2282 46.6929 90.7488 45.8452 91.5031 45.2369C92.2575 44.6285 93.1998 44.2965 94.1716 44.2966C94.1716 44.2966 94.1716 44.2966 94.1716 44.2966H235.039C235.668 44.2966 236.29 44.4359 236.858 44.7045C237.426 44.973 237.927 45.3641 238.324 45.8492C238.721 46.3343 239.004 46.9012 239.152 47.5085C239.301 48.1159 239.311 48.7485 239.182 49.3601L239.181 49.3608L219.118 143.627C219.076 143.825 218.879 143.953 218.679 143.911C218.479 143.869 218.351 143.673 218.393 143.474L238.456 49.2093C238.563 48.7047 238.554 48.1829 238.432 47.6819C238.31 47.1809 238.076 46.7133 237.749 46.3132C237.421 45.9131 237.008 45.5905 236.54 45.369C236.071 45.1474 235.558 45.0325 235.039 45.0326H94.1716Z"
        fill="#191717"
      />
      <path
        d="M96.2073 53.2122L77.4269 143.143H211.341L229.912 54.112L96.2073 53.2122Z"
        fill="#FFCAB2"
      />
      <path
        d="M57.218 140.042H47.7174C46.464 140.042 45.4479 141.035 45.4479 142.259V143.253C45.4479 144.477 46.464 145.469 47.7174 145.469H57.218C58.4714 145.469 59.4875 144.477 59.4875 143.253V142.259C59.4875 141.035 58.4714 140.042 57.218 140.042Z"
        fill="black"
      />
      <path
        d="M35.8318 140.042H26.5951C25.3765 140.042 24.3887 141.035 24.3887 142.259V143.253C24.3887 144.477 25.3765 145.469 26.5951 145.469H35.8318C37.0503 145.469 38.0382 144.477 38.0382 143.253V142.259C38.0382 141.035 37.0503 140.042 35.8318 140.042Z"
        fill="#F05559"
      />
      <path
        d="M79.4473 140.042H69.9467C68.6933 140.042 67.6772 141.035 67.6772 142.259V143.253C67.6772 144.477 68.6933 145.469 69.9467 145.469H79.4473C80.7007 145.469 81.7168 144.477 81.7168 143.253V142.259C81.7168 141.035 80.7007 140.042 79.4473 140.042Z"
        fill="black"
      />
      <path
        d="M100.897 140.042H91.396C90.1426 140.042 89.1265 141.035 89.1265 142.259V143.253C89.1265 144.477 90.1426 145.469 91.396 145.469H100.897C102.15 145.469 103.166 144.477 103.166 143.253V142.259C103.166 141.035 102.15 140.042 100.897 140.042Z"
        fill="black"
      />
      <path
        d="M123.126 140.042H113.625C112.372 140.042 111.356 141.035 111.356 142.259V143.253C111.356 144.477 112.372 145.469 113.625 145.469H123.126C124.379 145.469 125.395 144.477 125.395 143.253V142.259C125.395 141.035 124.379 140.042 123.126 140.042Z"
        fill="black"
      />
      <path
        d="M144.575 140.042H135.074C133.821 140.042 132.805 141.035 132.805 142.259V143.253C132.805 144.477 133.821 145.469 135.074 145.469H144.575C145.828 145.469 146.845 144.477 146.845 143.253V142.259C146.845 141.035 145.828 140.042 144.575 140.042Z"
        fill="black"
      />
      <path
        d="M157.225 140.394C156.205 140.394 155.378 141.227 155.378 142.255V143.256C155.378 144.284 156.205 145.118 157.225 145.118H166.493C167.513 145.118 168.34 144.284 168.34 143.256V142.255C168.34 141.227 167.513 140.394 166.493 140.394H157.225ZM154.644 142.255C154.644 140.819 155.8 139.655 157.225 139.655H166.493C167.918 139.655 169.074 140.819 169.074 142.255V143.256C169.074 144.692 167.918 145.857 166.493 145.857H157.225C155.8 145.857 154.644 144.692 154.644 143.256V142.255Z"
        fill="black"
      />
      <path d="M174.534 143.143H15.029V149.733H174.534V143.143Z" fill="black" />
      <path
        d="M174.144 143.121C174.144 142.919 174.31 142.756 174.514 142.756H219.011C219.216 142.756 219.382 142.919 219.382 143.121V149.368C219.382 149.57 219.216 149.733 219.011 149.733H174.514C174.31 149.733 174.144 149.57 174.144 149.368V143.121ZM174.885 143.486V149.003H218.641V143.486H174.885Z"
        fill="#191717"
      />
      <path
        d="M186.623 145.857H178.433V147.407H186.623V145.857Z"
        fill="black"
      />
      <path
        d="M215.482 145.857H206.903V147.407H215.482V145.857Z"
        fill="black"
      />
      <path
        d="M196.373 145.857H190.133V147.407H196.373V145.857Z"
        fill="black"
      />
      <path
        d="M199.103 145.857H193.643V147.407H199.103V145.857Z"
        fill="black"
      />
      <path
        d="M203.198 146.028C203.057 146.028 202.92 146.064 202.804 146.13C202.687 146.196 202.596 146.291 202.543 146.401C202.489 146.511 202.475 146.633 202.502 146.75C202.53 146.867 202.597 146.975 202.696 147.059C202.796 147.143 202.922 147.201 203.059 147.224C203.197 147.248 203.339 147.236 203.469 147.19C203.598 147.144 203.709 147.067 203.787 146.967C203.865 146.868 203.906 146.751 203.906 146.632C203.906 146.472 203.832 146.318 203.699 146.205C203.566 146.092 203.386 146.028 203.198 146.028ZM202.439 145.665C202.664 145.537 202.928 145.469 203.198 145.469C203.56 145.469 203.907 145.592 204.163 145.81C204.419 146.028 204.563 146.324 204.563 146.632C204.563 146.862 204.483 147.087 204.333 147.278C204.183 147.469 203.969 147.618 203.72 147.706C203.471 147.794 203.196 147.817 202.931 147.773C202.667 147.728 202.423 147.617 202.232 147.454C202.042 147.292 201.912 147.084 201.859 146.859C201.806 146.633 201.833 146.399 201.937 146.187C202.04 145.974 202.215 145.793 202.439 145.665Z"
        fill="black"
      />
      <path
        d="M174.534 142.756C174.749 142.756 174.924 142.889 174.924 143.054V149.435C174.924 149.6 174.749 149.733 174.534 149.733C174.318 149.733 174.144 149.6 174.144 149.435V143.054C174.144 142.889 174.318 142.756 174.534 142.756Z"
        fill="#211F1D"
      />
      <path
        d="M0 149.472C0 149.268 0.148258 149.103 0.331144 149.103H263.669C263.852 149.103 264 149.268 264 149.472V200.639C264 200.842 263.852 201.007 263.669 201.007C263.486 201.007 263.338 200.842 263.338 200.639V149.841H0.662288V200.639C0.662288 200.842 0.51403 201.007 0.331144 201.007C0.148258 201.007 0 200.842 0 200.639V149.472Z"
        fill="#191717"
      />
      <path
        d="M0 178.399C0 178.206 0.148258 178.05 0.331144 178.05H263.669C263.852 178.05 264 178.206 264 178.399C264 178.592 263.852 178.748 263.669 178.748H0.331144C0.148258 178.748 0 178.592 0 178.399Z"
        fill="#191717"
      />
      <path
        d="M218.35 149.103C218.543 149.103 218.7 149.259 218.7 149.452V200.659C218.7 200.851 218.543 201.007 218.35 201.007C218.157 201.007 218 200.851 218 200.659V149.452C218 149.259 218.157 149.103 218.35 149.103Z"
        fill="#191717"
      />
      <path
        d="M91.4449 71.8673H73.3346C72.1087 71.8673 70.8949 72.1033 69.7623 72.5618C68.6298 73.0203 67.6007 73.6923 66.7339 74.5394C65.8671 75.3866 65.1795 76.3923 64.7104 77.4992C64.2413 78.6061 63.9999 79.7924 63.9999 80.9904V87.2784C63.9999 88.4764 64.2413 89.6627 64.7104 90.7696C65.1795 91.8765 65.8671 92.8822 66.7339 93.7293C67.6007 94.5765 68.6298 95.2485 69.7623 95.707C70.8949 96.1655 72.1087 96.4015 73.3346 96.4015H88.7236L93.2802 100.851V96.2253C95.3954 95.8105 97.2984 94.6927 98.6656 93.0618C100.033 91.4309 100.78 89.3876 100.78 87.2792V80.9896C100.78 78.5701 99.7962 76.2498 98.0456 74.539C96.2949 72.8283 93.9206 71.8673 91.4449 71.8673Z"
        fill="#FE7C40"
      />
      <path
        d="M90.1922 84.7767C90.2103 84.5177 90.1713 84.2581 90.0778 84.0141C89.9843 83.7701 89.8383 83.5471 89.649 83.3591C89.4597 83.1712 89.2313 83.0224 88.9781 82.9221C88.7248 82.8219 88.4524 82.7723 88.1779 82.7767C87.0014 82.7767 86.1965 83.6018 86.1965 84.7767C86.1965 85.9197 86.9692 86.7764 88.2113 86.7764C89.3866 86.7764 90.1922 85.9197 90.1922 84.7767Z"
        fill="white"
      />
      <path
        d="M84.1922 84.7767C84.2103 84.5177 84.1713 84.258 84.0778 84.014C83.9842 83.77 83.8382 83.5469 83.6489 83.359C83.4596 83.171 83.231 83.0222 82.9778 82.922C82.7245 82.8218 82.452 82.7723 82.1775 82.7767C81.0011 82.7767 80.1965 83.6018 80.1965 84.7767C80.1965 85.9197 80.9688 86.7764 82.2113 86.7764C83.3866 86.7764 84.1922 85.9197 84.1922 84.7767Z"
        fill="white"
      />
      <path
        d="M78.1922 84.7767C78.2103 84.5177 78.1713 84.258 78.0778 84.014C77.9842 83.77 77.8382 83.5469 77.6489 83.359C77.4595 83.171 77.231 83.0222 76.9778 82.922C76.7245 82.8218 76.452 82.7723 76.1775 82.7767C75.0011 82.7767 74.1965 83.6018 74.1965 84.7767C74.1965 85.9197 74.9688 86.7764 76.2113 86.7764C77.3866 86.7764 78.1922 85.9197 78.1922 84.7767Z"
        fill="white"
      />
      <path
        d="M161.695 74.8067C158.123 68.3891 148.877 68.3891 145.305 74.8067L124.677 111.869C121.205 118.106 125.724 125.771 132.872 125.771H174.128C181.276 125.771 185.795 118.106 182.323 111.869L161.695 74.8067Z"
        fill="#F05559"
      />
      <path
        d="M156.625 88.3404C156.625 86.6177 155.226 85.2211 153.5 85.2211C151.774 85.2211 150.375 86.6177 150.375 88.3404V100.817C150.375 102.54 151.774 103.937 153.5 103.937C155.226 103.937 156.625 102.54 156.625 100.817V88.3404ZM153.5 116.414C155.226 116.414 156.625 115.017 156.625 113.294C156.625 111.572 155.226 110.175 153.5 110.175C151.774 110.175 150.375 111.572 150.375 113.294C150.375 115.017 151.774 116.414 153.5 116.414Z"
        fill="white"
      />
      <path
        d="M201.356 80.8882L195.8 79.341L193.85 84.8434L191.58 79.4658L186.122 81.3498L188.845 76.1844L184 73.0152L189.655 71.9671L189.066 66.1279L193.42 69.9833L197.529 65.8784L197.284 71.7301L203 72.4413L198.339 75.8974L201.356 80.8882Z"
        fill="white"
      />
    </svg>
  );
}

export default SvgComponent;
