enum EVENT_CATEGORY {
  PAGE = 'page',
  BUTTON = 'button',
}

const EVENT_ACTION = {
  PAGE: {
    VIEW: 'pageview',
  },
  BUTTON: {
    CLICK: 'buttonClick',
  },
};

const LABEL_CONSTANTS = {
  DASHBOARD_PAGE: {
    NAVBAR_ITEM: 'DashboardPage.navBarItem.:buttonName',
  },
  CATALOG_PAGE: {
    COURSE_LIST_FILTER: 'CatalogPage.courseFilter.:buttonName',
    COURSE_LIST_SIDEBAR: 'CatalogPage.courseListSideBar.:buttonName',
  },
  LEARNING_TRACKS_PAGE: {
    LIST_ITEM: 'LearningTracksPage.listItem.:buttonName',
  },
  ACCOUNT_PAGE: {
    SIDE_BAR_MENU: 'AccountPage.sideBarMenu.:buttonName',
    MY_PACKAGES: 'AccountPage.myPackages.:buttonName',
  },
  COURSE_DETAIL_PAGE: {
    COURSE_OUTLINE_ITEM: 'CourseDetailPage.courseOutlineItem.:buttonName',
    MATERIAL_ITEM: 'CourseDetailPage.materialItem.:buttonName',
  },
  EVENT_PAGE: {
    EVENT_CALENDAR_SESSION: 'EventPage.eventCalendarSession.:buttonName',
  },
  SEARCH_PAGE: {
    SEARCH_SIDEBAR: 'SearchPage.searchSideBar.:buttonName',
  },
  PROFILE_DROPDOWN: {
    DROPDOWN_ITEM: 'ProfileDropdown.dropdownItem.:buttonName',
  },
  NAVBAR: {
    NAVBAR_ITEM: 'Navbar.navBarItem.:buttonName',
  },
  LOGIN_PAGE: {
    CREATE_AN_ACCOUNT: 'LoginPage.createAnAccount.:buttonName',
  },
  SIGN_UP_PAGE: {
    SIGN_UP: 'SignUpPage.signUp.:buttonName',
  },
  PAYMENT_PAGE: {
    APPLY_COUPON: 'PaymentPage.applyCoupon.:buttonName',
    PROCEED_PAYMENT: 'PaymentPage.proceedPayment.:buttonName',
    REQUEST_INVOICE: 'PaymentPage.requestInvoice.:buttonName',
    CANCEL_REQUEST_INVOICE: 'PaymentPage.cancelRequestInvoice.:buttonName',
  },
};

const getLabel = (data: { item: string; buttonName: string }) => {
  return data.item.replace(
    ':buttonName',
    data.buttonName.replaceAll(' ', '-').toLowerCase(),
  );
};

export const GOOGLE_ANALYTICS_EVENTS = {
  EVENT_CATEGORY,
  EVENT_ACTION,
  LABEL_CONSTANTS,
  getLabel,
};
