import { SVGProps } from 'react';

function SvgComponent(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.6256 2.68581C5.18411 1.84813 6.43432 0.666687 8.35026 0.666687C9.76995 0.666687 10.7841 1.38651 11.4215 2.22005C11.8677 2.80351 12.1536 3.4726 12.2782 4.05003C12.8016 4.13484 13.4081 4.33685 13.9481 4.72566C14.7448 5.29926 15.3503 6.24941 15.3503 7.66669C15.3503 9.08397 14.7448 10.0341 13.9481 10.6077C13.1855 11.1568 12.2903 11.3334 11.6836 11.3334H8.68359V6.94283L9.54552 7.80476C9.80587 8.06511 10.228 8.06511 10.4883 7.80476C10.7487 7.54441 10.7487 7.1223 10.4883 6.86195L8.48833 4.86195C8.22798 4.6016 7.80587 4.6016 7.54552 4.86195L5.54552 6.86195C5.28517 7.1223 5.28517 7.54441 5.54552 7.80476C5.80587 8.06511 6.22798 8.06511 6.48833 7.80476L7.35026 6.94283V11.3334H5.01693C4.29913 11.3334 3.23728 11.1235 2.33572 10.4744C1.40017 9.80078 0.683594 8.68397 0.683594 7.00002C0.683594 5.31607 1.40017 4.19926 2.33572 3.52566C3.08479 2.98634 3.9445 2.75025 4.6256 2.68581Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
