import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_178_300455)">
        <g clipPath="url(#clip1_178_300455)">
          <path
            d="M7.66211 13.3368C7.66211 12.962 7.96592 12.6582 8.34068 12.6582H12.2739C12.6487 12.6582 12.9525 12.962 12.9525 13.3368V18.9338C12.9525 19.4461 12.4066 19.7736 11.9546 19.5325L10.6266 18.8243C10.4271 18.7178 10.1876 18.7178 9.98798 18.8243L8.66001 19.5325C8.20801 19.7736 7.66211 19.4461 7.66211 18.9338V13.3368Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.43649 1.40927C9.33735 0.64157 10.6623 0.64157 11.5632 1.40927L12.5847 2.27981C12.7134 2.38947 12.8734 2.45575 13.0419 2.46919L14.3798 2.57596C15.5597 2.67011 16.4966 3.60701 16.5907 4.78686L16.6975 6.12475C16.7109 6.29329 16.7772 6.45328 16.8869 6.58196L17.7574 7.60349C18.5251 8.50434 18.5251 9.82932 17.7574 10.7302L16.8869 11.7517C16.7772 11.8804 16.7109 12.0404 16.6975 12.2089L16.5907 13.5468C16.4966 14.7267 15.5597 15.6635 14.3798 15.7577L13.0419 15.8645C12.8734 15.8779 12.7134 15.9442 12.5847 16.0538L11.5632 16.9244C10.6623 17.6921 9.33735 17.6921 8.4365 16.9244L7.41497 16.0538C7.28629 15.9442 7.12629 15.8779 6.95776 15.8645L5.61987 15.7577C4.44002 15.6635 3.50312 14.7267 3.40897 13.5468L3.3022 12.2089C3.28875 12.0404 3.22248 11.8804 3.11282 11.7517L2.24228 10.7302C1.47458 9.82932 1.47458 8.50434 2.24228 7.60349L3.11282 6.58196C3.22248 6.45328 3.28875 6.29329 3.3022 6.12475L3.40897 4.78686C3.50312 3.60701 4.44002 2.67011 5.61987 2.57596L6.95776 2.46919C7.12629 2.45575 7.28629 2.38947 7.41497 2.27981L8.43649 1.40927ZM12.9784 8.12809C13.2921 7.81434 13.2921 7.30564 12.9784 6.99188C12.6646 6.67812 12.1559 6.67812 11.8421 6.99188L9.19656 9.63747L8.15781 8.59873C7.84406 8.28497 7.33536 8.28497 7.0216 8.59873C6.70784 8.91248 6.70784 9.42118 7.0216 9.73494L8.62845 11.3418C8.94221 11.6555 9.45091 11.6555 9.76466 11.3418L12.9784 8.12809Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.9782 6.99167C13.292 7.30542 13.292 7.81412 12.9782 8.12788L9.76451 11.3416C9.45075 11.6553 8.94205 11.6553 8.6283 11.3416L7.02145 9.73473C6.70769 9.42097 6.70769 8.91227 7.02145 8.59851C7.33521 8.28476 7.84391 8.28476 8.15766 8.59851L9.1964 9.63725L11.842 6.99167C12.1558 6.67791 12.6645 6.67791 12.9782 6.99167Z"
            fill="transparent"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_178_300455">
          <rect width="20" height="20" fill="white" />
        </clipPath>
        <clipPath id="clip1_178_300455">
          <rect
            width="16.6667"
            height="19.2311"
            fill="white"
            transform="translate(1.6665 0.833496)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
