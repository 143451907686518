import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={16}
      height={17}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33337 3.62628C7.33337 3.35664 7.17095 3.11355 6.92183 3.01036C6.67271 2.90717 6.38597 2.96421 6.1953 3.15488L1.52864 7.82154C1.26829 8.08189 1.26829 8.504 1.52864 8.76435L6.1953 13.431C6.38597 13.6217 6.67271 13.6787 6.92183 13.5755C7.17095 13.4723 7.33337 13.2293 7.33337 12.9596V10.9429C8.46102 10.9355 9.88617 11.0319 11.0889 11.3315C11.8139 11.5121 12.404 11.7536 12.7988 12.0488C13.1768 12.3314 13.3334 12.6252 13.3334 12.9596C13.3334 13.3278 13.6319 13.6263 14 13.6263C14.3682 13.6263 14.6667 13.3278 14.6667 12.9596C14.6667 11.6761 14.268 9.85241 13.054 8.33482C11.9076 6.90188 10.0784 5.7983 7.33337 5.64461V3.62628Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
