import { SVGProps } from 'react';

function SvgComponent(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66675 9.33317C2.66675 5.65127 5.65152 2.6665 9.33341 2.6665C13.0153 2.6665 16.0001 5.65127 16.0001 9.33317C16.0001 13.0151 13.0153 15.9998 9.33341 15.9998C5.65152 15.9998 2.66675 13.0151 2.66675 9.33317ZM7.33342 8.6665C7.7016 8.6665 8.00008 8.36803 8.00008 7.99984C8.00008 7.63165 7.7016 7.33317 7.33342 7.33317C6.96523 7.33317 6.66675 7.63165 6.66675 7.99984C6.66675 8.36803 6.96523 8.6665 7.33342 8.6665ZM7.83794 10.0665C7.59728 9.78784 7.17629 9.75705 6.89765 9.99771C6.619 10.2384 6.58821 10.6594 6.82888 10.938C7.36846 11.5628 8.18925 11.9999 9.3334 11.9999C10.4775 11.9999 11.2983 11.5628 11.8379 10.938C12.0786 10.6594 12.0478 10.2384 11.7692 9.99771C11.4905 9.75705 11.0695 9.78784 10.8289 10.0665C10.5497 10.3897 10.1011 10.6665 9.3334 10.6665C8.56568 10.6665 8.11712 10.3897 7.83794 10.0665ZM12.0001 7.99984C12.0001 8.36803 11.7016 8.6665 11.3334 8.6665C10.9652 8.6665 10.6667 8.36803 10.6667 7.99984C10.6667 7.63165 10.9652 7.33317 11.3334 7.33317C11.7016 7.33317 12.0001 7.63165 12.0001 7.99984Z"
        fill="#B91E44"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666748 1.6665C0.666748 1.11422 1.11446 0.666504 1.66675 0.666504H6.33341C6.8857 0.666504 7.33341 1.11422 7.33341 1.6665V4.6665C7.33341 5.21879 6.8857 5.6665 6.33341 5.6665H5.60956C5.52115 5.6665 5.43637 5.70162 5.37385 5.76414L4.70719 6.4308C4.31666 6.82133 3.6835 6.82133 3.29297 6.4308L2.62631 5.76414C2.5638 5.70162 2.47901 5.6665 2.39061 5.6665H1.66675C1.11446 5.6665 0.666748 5.21879 0.666748 4.6665V1.6665ZM2.33341 1.99984C2.14932 1.99984 2.00008 2.14908 2.00008 2.33317C2.00008 2.51727 2.14932 2.6665 2.33341 2.6665H5.66675C5.85084 2.6665 6.00008 2.51727 6.00008 2.33317C6.00008 2.14908 5.85084 1.99984 5.66675 1.99984H2.33341ZM2.33341 3.33317C2.14932 3.33317 2.00008 3.48241 2.00008 3.6665C2.00008 3.8506 2.14932 3.99984 2.33341 3.99984H4.00008C4.18418 3.99984 4.33341 3.8506 4.33341 3.6665C4.33341 3.48241 4.18418 3.33317 4.00008 3.33317H2.33341Z"
        fill="#FE7C40"
      />
    </svg>
  );
}

export default SvgComponent;
