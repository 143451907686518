import { SVGProps } from 'react';

function SvgComponent(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 17C0 18.6569 1.34315 20 3 20H15C16.6569 20 18 18.6569 18 17V6.82843C18 6.03278 17.6839 5.26972 17.1213 4.70711L13.2929 0.878679C12.7303 0.316072 11.9672 0 11.1716 0H3C1.34315 0 0 1.34315 0 3V17ZM14 9C14 8.44771 13.5523 8 13 8C12.4477 8 12 8.44771 12 9V15C12 15.5523 12.4477 16 13 16C13.5523 16 14 15.5523 14 15V9ZM9.99999 11C9.99999 10.4477 9.55228 10 8.99999 10C8.44771 10 7.99999 10.4477 7.99999 11V15C7.99999 15.5523 8.44771 16 8.99999 16C9.55228 16 9.99999 15.5523 9.99999 15V11ZM5.99999 12.995C5.99723 12.4427 5.54728 11.9973 4.995 12C4.44273 12.0028 3.99725 12.4527 4.00001 13.005L4.01001 15.005C4.01277 15.5573 4.46271 16.0027 5.01499 16C5.56727 15.9972 6.01274 15.5473 6.00998 14.995L5.99999 12.995Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
