import { SVGProps } from 'react';

function SvgComponent(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66675 9.99996C1.66675 5.39759 5.39771 1.66663 10.0001 1.66663C14.6025 1.66663 18.3334 5.39759 18.3334 9.99996C18.3334 14.6023 14.6025 18.3333 10.0001 18.3333C5.39771 18.3333 1.66675 14.6023 1.66675 9.99996ZM10.8334 6.66663C10.8334 6.20639 10.4603 5.83329 10.0001 5.83329C9.53984 5.83329 9.16675 6.20639 9.16675 6.66663C9.16675 7.12686 9.53984 7.49996 10.0001 7.49996C10.4603 7.49996 10.8334 7.12686 10.8334 6.66663ZM10.0001 14.1666C10.4603 14.1666 10.8334 13.7935 10.8334 13.3333V9.99996C10.8334 9.53972 10.4603 9.16663 10.0001 9.16663C9.53984 9.16663 9.16675 9.53972 9.16675 9.99996V13.3333C9.16675 13.7935 9.53984 14.1666 10.0001 14.1666Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
