import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={17}
      height={16}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.83333 6.00003L3.88675 6.00003C4.27599 6.00003 4.64579 5.82994 4.8991 5.53441L7.32716 2.70167C7.72996 2.23174 8.5 2.51659 8.5 3.13553L8.5 12.8645C8.5 13.4835 7.72996 13.7683 7.32716 13.2984L4.8991 10.4656C4.64579 10.1701 4.27599 10 3.88675 10L3.83333 10C3.09695 10 2.5 9.40307 2.5 8.66669L2.5 7.33336C2.5 6.59698 3.09695 6.00003 3.83333 6.00003Z"
        fill="#4C4D4F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9817 2.87427C12.2353 2.60732 12.6573 2.59648 12.9242 2.85006C15.9141 5.69023 15.9141 10.3099 12.9242 13.1501C12.6573 13.4037 12.2353 13.3928 11.9817 13.1259C11.7282 12.8589 11.739 12.437 12.0059 12.1834C14.4425 9.8689 14.4425 6.13126 12.0059 3.81677C11.739 3.56319 11.7282 3.14122 11.9817 2.87427ZM10.0166 4.74094C10.2702 4.47399 10.6922 4.46315 10.9591 4.71673C12.8638 6.52596 12.8638 9.4742 10.9591 11.2834C10.6922 11.537 10.2702 11.5262 10.0166 11.2592C9.76307 10.9923 9.7739 10.5703 10.0409 10.3167C11.3921 9.03317 11.3921 6.967 10.0409 5.68344C9.7739 5.42986 9.76307 5.00789 10.0166 4.74094Z"
        fill="#4C4D4F"
      />
    </svg>
  );
}

export default SvgComponent;
