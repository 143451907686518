import { transform } from 'lodash';

export type ERROR_TYPE = {
  code: string;
  message: string;
  data?: Record<string, any>;
};

export const ERROR_CODES = {
  ERROR_USER_DEACTIVATED: {
    code: 'SEAC_101',
    message: 'User is deactivated',
  },

  // Auth
  AUTH_PROVIDER_NOT_FOUND: {
    code: 'SEAC_AUTH_101',
    message: 'Auth provider not found',
  },
  WRONG_CURRENT_PASSWORD: {
    code: 'SEAC_AUTH_102',
    message: 'Wrong current password',
  },
  INCORRECT_EMAIL_OR_PASSWORD: {
    code: 'SEAC_AUTH_103',
    message: 'Your email address or password is incorrect',
  },
  RECAPTCHA_VERIFICATION: {
    code: 'SEAC_AUTH_104',
    message: 'reCAPTCHA verification failed. Please try again.',
  },
  EMAIL_ALREADY_EXIST: {
    code: 'SEAC_AUTH_105',
    message: 'Email already exists',
  },
  EMAIL_ALREADY_INVITE: {
    code: 'SEAC_AUTH_106',
    message: 'Email already invited',
  },
  PASSWORD_ALREADY_USED: {
    code: 'SEAC_AUTH_107',
    message: 'Password already in use',
  },
  PHONE_NUM_ALREADY_USED: {
    code: 'SEAC_AUTH_108',
    message: 'Phone number already in use',
  },

  // Central
  INVALID_SUBSCRIPTION: {
    code: 'SEAC_CENTRAL_101',
    message: 'Current subscription plan does not allow this action',
  },
  SUBSCRIPTION_WILL_EXPIRE: {
    code: 'SEAC_CENTRAL_102',
    message: 'Current subscription plan will expire before action will be done',
  },
  PREVIOUS_OUTLINE_NOT_BOOKED: {
    code: 'SEAC_CENTRAL_103',
    message:
      'Previous outline session should be booked to book this session according to pre booking rule',
  },
  SESSION_BOOKING_NOT_ALLOWED_BEFORE_PRE_BOOKING: {
    code: 'SEAC_CENTRAL_104',
    message:
      'Session booking is not allowed to book before previous outline session end time according to pre booking rule',
  },
  SESSION_BOOKING_OVERLAP: {
    code: 'SEAC_CENTRAL_105',
    message: 'This session booking overlaps with another booked session',
  },
  CANNOT_CANCEL_PRIVATE_COURSE_SESSION: {
    code: 'SEAC_CENTRAL_106',
    message: 'Cannot cancel a private course session',
  },
  ASSESSMENT_NOT_FOUND: {
    code: 'SEAC_CENTRAL_107',
    message: 'Assessment not found',
  },
  INSTRUCTOR_SESSION_TIME_OVERLAP: {
    code: 'SEAC_CENTRAL_108',
    message: 'This session overlaps with another session for the instructor',
  },
  SESSION_ADD_REGISTRANTS_EXCEED_SEATS_LIMIT: {
    code: 'SEAC_CENTRAL_109',
    message: 'Registrant over session limit',
  },
  SESSION_BOOKING_NOT_ALLOWED_BEFORE_TASK_FINISHED: {
    code: 'SEAC_CENTRAL_110',
    message:
      'Session booking is not allowed to book before the required tasks is completed',
  },
  SESSION_BULK_UPLOAD_FAILED: {
    code: 'SEAC_CENTRAL_111',
    message:
      'Failed to bulk upload sessions. Please view issue in upload history page',
  },
  RETAIL_PLAN_REQUIRED: {
    code: 'SEAC_CENTRAL_112',
    message: 'Retail plan required',
  },
  RETAIL_ALREADY_BOUGHT: {
    code: 'SEAC_CENTRAL_113',
    message: 'There are users who have already bought this retail package',
  },
  SUBSCRIPTION_PLAN_BUNDLE_ITEM_DUPLICATE: {
    code: 'SEAC_CENTRAL_114',
    message:
      'Subscription plan bundle must not have duplicate subscription plan',
  },
  UNSUPPORTED_SSO_ORGANIZATION_USER_INVITATION: {
    code: 'SEAC_CENTRAL_115',
    message: 'Inviting user to SSO organization is not supported',
  },
  SESSION_NO_SEAT: {
    code: 'SEAC_CENTRAL_116',
    message: 'No seat available for this session',
  },
  SAME_OUTLINE_SESSION_ALREADY_BOOKED: {
    code: 'SEAC_CENTRAL_117',
    message: 'A session belonging to this outline is already booked',
  },
  SESSION_BOOKING_ONGOING: {
    code: 'SEAC_CENTRAL_118',
    message: 'Booking ongoing session is not allowed',
  },
  SESSION_BOOKING_ENDED: {
    code: 'SEAC_CENTRAL_119',
    message: 'Booking ended session is not allowed',
  },
  DB_ERROR: {
    code: 'SEAC_CENTRAL_120',
    message: 'Database Error',
  },

  // Payment
  CANNOT_UPGRADE_PLAN: {
    code: 'SEAC_PAYMENT_101',
    message:
      'Contact your admin to upgrade/renew/purchase this subscription plan',
  },
  COUPON_NOT_FOUND: {
    code: 'SEAC_PAYMENT_102',
    message: 'Coupon not found',
  },
  COUPON_INVALID: {
    code: 'SEAC_PAYMENT_103',
    message: 'Invalid coupon',
  },
  COUPON_REDEMPTION_EXCEED: {
    code: 'SEAC_PAYMENT_104',
    message: 'Redemption limit reached',
  },
  NO_SKU_INFORMATION: {
    code: 'SEAC_PAYMENT_105',
    message: 'No SKU information. Please assign SKU to this plan',
  },
  PLAN_NOT_FOUND: {
    code: 'SEAC_PAYMENT_106',
    message: 'Plan not found',
  },
  SUBSCRIPTION_PLAN_NOT_IN_BUNDLE: {
    code: 'SEAC_PAYMENT_105',
    message:
      "Order's subscription plan and/or base subscription plan are not in subscription plan package bundle",
  },

  // Notification
  OVERLAPPING_SYSTEM_ANNOUNCEMENTS: {
    code: 'SEAC_NOTIFICATION_101',
    message:
      'Cannot not have multiple active system announcements within the same dates',
  },
};

export type ErrorCodeKeys = keyof typeof ERROR_CODES;
export type ErrorCode = typeof ERROR_CODES[ErrorCodeKeys]['code'];
export type ErrorCodesMap = {
  [key in ErrorCode]: {
    key: ErrorCodeKeys;
    message: string;
  };
};

// In case we need to find the error via code instead of the usual key
export const ERROR_CODES_MAP: ErrorCodesMap = transform(
  ERROR_CODES,
  (result, errorType, key) => {
    result[errorType.code] = { key, message: errorType.message };
  },
  {},
);
