import { SVGProps } from 'react';

function SvgComponent(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.950195 16.5C0.950195 7.66344 8.11364 0.5 16.9502 0.5C25.7868 0.5 32.9502 7.66344 32.9502 16.5C32.9502 25.3366 25.7868 32.5 16.9502 32.5C8.11364 32.5 0.950195 25.3366 0.950195 16.5Z"
        fill="#ECEDED"
      />
      <path
        d="M21.9503 9C22.4103 9 22.7836 9.37333 22.7836 9.83333V23.1667C22.7836 23.6267 22.4103 24 21.9503 24H11.9503C11.4903 24 11.1169 23.6267 11.1169 23.1667V9.83333C11.1169 9.37333 11.4903 9 11.9503 9H21.9503ZM18.6169 15.6667C18.1569 15.6667 17.7836 16.04 17.7836 16.5C17.7836 16.96 18.1569 17.3333 18.6169 17.3333C19.0769 17.3333 19.4503 16.96 19.4503 16.5C19.4503 16.04 19.0769 15.6667 18.6169 15.6667Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
