import { DEFAULT_LOGO } from '../../constants/images';
import Image from 'next/image';
import * as React from 'react';

interface LogoProps {
  color?: 'white' | 'brand'; //Leave this props incase there are different styles for logo.
}
const Logo: React.FunctionComponent<LogoProps> = () => {
  return (
    <Image width={81} height={36} src={DEFAULT_LOGO} alt="YourNextU Logo" />
  );
};

export default Logo;
