import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.33333 1.66113C6.77056 1.66113 7.125 2.01557 7.125 2.4528V3.24447H11.875V2.4528C11.875 2.01557 12.2294 1.66113 12.6667 1.66113C13.1039 1.66113 13.4583 2.01557 13.4583 2.4528V3.24447H14.25C15.5617 3.24447 16.625 4.30779 16.625 5.61947V6.41113H2.375V5.61947C2.375 4.30779 3.43832 3.24447 4.75 3.24447H5.54167V2.4528C5.54167 2.01557 5.89611 1.66113 6.33333 1.66113Z"
        fill="#888A8D"
      />
      <path
        d="M2.375 7.99512V15.1201C2.375 16.4318 3.43832 17.4951 4.75 17.4951H14.25C15.5617 17.4951 16.625 16.4318 16.625 15.1201V7.99512H2.375Z"
        fill="#C1C2C4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2954 10.3257C12.5708 10.6195 12.5708 11.0956 12.2954 11.3894L9.47513 14.3977C9.19978 14.6914 8.75335 14.6914 8.478 14.3977L7.06784 12.8935C6.79249 12.5998 6.79249 12.1236 7.06784 11.8299C7.34319 11.5362 7.78962 11.5362 8.06497 11.8299L8.97656 12.8023L11.2983 10.3257C11.5737 10.032 12.0201 10.032 12.2954 10.3257Z"
        fill="#888A8D"
      />
    </svg>
  );
}

export default SvgComponent;
