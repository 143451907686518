import { AppProps } from 'next/app';
import '../styles/global.css';
import 'focus-visible';
import I18nProvider from '../src/i18n/Provider';
import { ToastProvider } from 'react-toast-notifications';
import SnackBar from '../src/ui-kit/SnackBar';
import ToastContainer from '../src/ui-kit/ToastContainer';
import { ErrorBoundary } from '@sentry/nextjs';
import { SystemError } from '../src/ui-kit/SystemError';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import ReactGA from 'react-ga4';
import { GOOGLE_ANALYTICS_EVENTS } from '../src/constants/googleAnalyticsEvent';
import * as Sentry from '@sentry/nextjs';
import Script from 'next/script';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { datadogRum } from '@datadog/browser-rum';
import config from '../src/config';
import SnackBarV2 from '@ui-kit/v2/SnackBarV2';

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();

  try {
    ReactGA.initialize(process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID);
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
  }

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/sw.js', { scope: '/' });
    }

    return () => {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
          registrations.forEach((registration) => registration.unregister());
        });
      }
    };
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: GOOGLE_ANALYTICS_EVENTS.EVENT_ACTION.PAGE.VIEW,
      page: router.asPath,
    });
  }, [router.asPath]);

  useEffect(() => {
    if (!config.IS_PRODUCTION) {
      return;
    }

    datadogRum.init({
      applicationId: config.DATADOG_APP_ID,
      clientToken: config.DATADOG_TOKEN,
      site: 'datadoghq.com',
      service: 'yournextu-platform',
      env: 'prod',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',
    });

    datadogRum.startSessionReplayRecording();
  }, [router.asPath]);

  return (
    <ErrorBoundary fallback={SystemError}>
      <I18nProvider>
        {/* //TODO: Change this to new version*/}
        <ToastProvider
          autoDismiss={true}
          autoDismissTimeout={3000}
          placement={
            router.pathname.includes('/account')
              ? 'bottom-center'
              : 'top-center'
          }
          newestOnTop
          components={{
            ToastContainer,
            Toast: router.pathname.includes('/account') ? SnackBarV2 : SnackBar,
          }}
        >
          {config.IS_PRODUCTION && (
            <Script
              id="full-story"
              dangerouslySetInnerHTML={{
                __html: `
              window['_fs_host'] = 'fullstory.com';
              window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
              window['_fs_org'] = '${process.env.NEXT_PUBLIC_FULL_STORY_ORG_ID}';
              window['_fs_namespace'] = 'FS';
              (function(m,n,e,t,l,o,g,y){
                if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
                g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
                o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;
                y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
                g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
                g.anonymize=function(){g.identify(!!0)};
                g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
                g.log = function(a,b){g("log",[a,b])};
                g.consent=function(a){g("consent",!arguments.length||a)};
                g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
                g.clearUserCookie=function(){};
                g.setVars=function(n, p){g('setVars',[n,p]);};
                g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];
                if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};
                g._v="1.3.0";
              })(window,document,window['_fs_namespace'],'script','user');
              `,
              }}
            />
          )}
          <Script
            id="onboarding"
            dangerouslySetInnerHTML={{
              __html: `(function(g,u,i,d,e,s){
                g[e]=g[e]||[];
                var f=u.getElementsByTagName(i)[0];
                var k=u.createElement(i);k.async=true;k.src='https://static.userguiding.com/media/user-guiding-'+s+'-embedded.js';
                f.parentNode.insertBefore(k,f);
                if(g[d])return;
                var ug=g[d]={q:[]};
                ug.c=function(n){return function(){ug.q.push([n,arguments])};};
                var m=['previewGuide','finishPreview','track','identify','hideChecklist','launchChecklist'];
                for(var j=0;j<m.length;j+=1){ug[m[j]]=ug.c(m[j]);}
              })(window,document,'script','userGuiding','userGuidingLayer','81074052ZPDID');
              `,
            }}
          />
          <Component {...pageProps} />
        </ToastProvider>
      </I18nProvider>
    </ErrorBoundary>
  );
}
