import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 3C3.34315 3 2 4.34315 2 6V14C2 15.6569 3.34315 17 5 17H9V19H8C7.44772 19 7 19.4477 7 20C7 20.5523 7.44772 21 8 21H16C16.5523 21 17 20.5523 17 20C17 19.4477 16.5523 19 16 19H15V17H19C20.6569 17 22 15.6569 22 14V6C22 4.34315 20.6569 3 19 3H5Z"
        fill="#C1C2C4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.3335 10.0002C8.3335 7.97512 9.97512 6.3335 12.0002 6.3335C14.0252 6.3335 15.6668 7.97512 15.6668 10.0002C15.6668 12.0252 14.0252 13.6668 12.0002 13.6668C9.97512 13.6668 8.3335 12.0252 8.3335 10.0002ZM11.5291 8.42486C11.4291 8.35818 11.3005 8.35197 11.1945 8.40868C11.0885 8.4654 11.0224 8.57584 11.0224 8.69604V11.3035C11.0224 11.4237 11.0885 11.5341 11.1945 11.5908C11.3005 11.6475 11.4291 11.6413 11.5291 11.5746L13.4846 10.2709C13.5753 10.2105 13.6298 10.1087 13.6298 9.99975C13.6298 9.89077 13.5753 9.78901 13.4846 9.72856L11.5291 8.42486Z"
        fill="#888A8D"
      />
    </svg>
  );
}

export default SvgComponent;
