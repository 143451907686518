const defaultTheme = require('tailwindcss/defaultTheme');

// Somehow default font family contains Noto Sans (TH character will fallback to it)
const sans = defaultTheme.fontFamily.sans.filter(
  (font) => font !== '"Noto Sans"',
);

/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ['./pages/**/*.{js,ts,jsx,tsx}', './src/**/*.{js,ts,jsx,tsx}'],
  safelist: ['bg-checked-dropdown'],
  theme: {
    screens: {
      sm: '640px',
      md: '768px',
      lg: '1104px',
      xl: '1280px',
      '2xl': '1400px',
    },
    colors: {
      transparent: 'transparent',
      current: 'currentColor',

      brand: {
        primary: '#A80030',
        secondary: '#00263E',
        tertiary: '#FE5000',
      },
      maroon: {
        100: '#FFF8FA',
        200: '#FFE7EE',
        300: '#B91E44',
        400: '#A80030', // Brand color primary
        500: '#860026',
        600: '#65001D',
      },
      blue: {
        100: '#F2F4F5',
        200: '#D9DFE2',
        300: '#335165',
        400: '#00263E', // Brand color secondary
        500: '#001C30',
        600: '#001322',
      },
      orange: {
        50: '#FFF2E5',
        100: '#FFEDE5',
        200: '#FFCAB2',
        300: '#FE7C40',
        400: '#FE5000', // Brand color tertiary
        500: '#D24200',
        600: '#8B2C00',
      },
      green: {
        100: '#D3FEE6',
        150: '#B2F6D1',
        200: '#2FC373',
        300: '#18864A',
        400: '#77BB5D',
      },
      yellow: {
        100: '#FFEFC6',
        200: '#FFD15A',
        300: '#BE9528',
        400: '#92400E',
      },
      red: {
        100: '#FFB6B8',
        200: '#F05559',
        300: '#B1292C',
      },
      black: '#000000',
      'black-transparent': {
        100: 'rgba(0, 0, 0, 0.1)',
        200: 'rgba(0, 0, 0, 0.3)',
      },
      white: '#FFFFFF',
      gray: {
        100: '#FBFBFB',
        200: '#ECEDED',
        300: '#D2D2D2',
        400: '#C1C2C4',
        500: '#888A8D',
        600: '#6A6B6E',
        650: '#4C4D4F',
        700: '#2E2E30',
        900: '#111827',
      },
      // --V2 Color https://www.figma.com/file/thIjlBmpNgw3exEJarAnd4/CLP-UX-Wire-Flow?node-id=2848%3A28483&t=tc5GKpWHUdj5wBl1-0 --
      grayv2: {
        50: '#F7F7F8',
        100: '#EFF0F0',
        200: '#DDDDDF',
        300: '#BBBDC1',
        400: '#A5A7AC',
        500: '#8E9197',
        600: '#727479',
        700: '#55575B',
        800: '#393A3C',
        900: '#1C1D1E',
      },
      seac1v2: {
        50: '#F6E5EA',
        100: '#EECCD6',
        200: '#DC99AC',
        300: '#CB6683',
        400: '#B93359',
        500: '#A80030',
        600: '#860026',
        700: '#65001D',
        800: '#430013',
        900: '#22000A',
      },
      seac2v2: {
        50: '#E5F0F7',
        100: '#D9E8F2',
        200: '#BCD6E6',
        300: '#89B5D1',
        400: '#659BBD',
        500: '#457DA1',
        600: '#225F87',
        700: '#144D71',
        800: '#003252',
        900: '#00263E',
      },
      ynu1v2: {
        50: '#E5F0F7',
        100: '#D9E8F2',
        200: '#BCD6E6',
        300: '#89B5D1',
        400: '#659BBD',
        500: '#457DA1',
        600: '#225F87',
        700: '#144D71',
        800: '#003252',
        900: '#00263E',
      },
      shadesv2: {
        0: '#FFFFFF',
        100: '#000000',
      },
      greenv2: {
        50: '#E7F8F2',
        100: '#CFF1E6',
        200: '#9FE3CD',
        300: '#70D5B3',
        400: '#40C79A',
        500: '#10B981',
        600: '#0D9467',
        700: '#0A6F4D',
        800: '#064A34',
        900: '#03251A',
      },
      yellowv2: {
        50: '#FFFAE5',
        100: '#FFF5CC',
        200: '#FFEB99',
        300: '#FFE166',
        400: '#FFD733',
        500: '#FFCD00',
        600: '#CCA400',
        700: '#997B00',
        800: '#665200',
        900: '#332900',
      },
      orangev2: {
        50: '#FFF2E5',
        100: '#FFE5CB',
        200: '#FFCC98',
        300: '#FFB265',
        400: '#FF9932',
        500: '#FE7F00',
        600: '#D66B00',
        700: '#984C00',
        800: '#663300',
        900: '#331900',
      },
      redv2: {
        50: '#FFE9E9',
        100: '#FFD4D3',
        200: '#FFA9A6',
        300: '#FF7D7A',
        400: '#FF524D',
        500: '#FF2721',
        600: '#D51F1A',
        700: '#AA1814',
        800: '#80100D',
        900: '#550907',
      },
      pinkv2: {
        50: '#FDEDF5',
        100: '#FBDAEB',
        200: '#F7B6D6',
        300: '#F491C2',
        400: '#F06DAD',
        500: '#EC4899',
        600: '#BF3A7C',
        700: '#922C5E',
        800: '#651D41',
        900: '#380F23',
      },
      purplev2: {
        50: '#F8EFFF',
        100: '#F1DFFF',
        200: '#E3BFFF',
        300: '#D59FFF',
        400: '#C77FFF',
        500: '#B95FFF',
        600: '#964CD0',
        700: '#7339A0',
        800: '#502671',
        900: '#2D1341',
      },
      bluev2: {
        50: '#E5F0FD',
        100: '#CCE1FB',
        200: '#99C4F7',
        300: '#66A6F3',
        400: '#3389EF',
        500: '#006BEB',
        600: '#0056BC',
        700: '#00408D',
        800: '#002B5E',
        900: '#00152F',
      },
      // --End of V2 Color--
      backdrop: {
        modal: 'rgba(0, 0, 0, 0.8)',
        lightbox: 'rgba(255, 255, 255, 0.8)',
        disabled: 'rgba(255, 255, 255, 0.2)',
      },
      learning: {
        online: {
          100: '#DEF4FF',
          200: '#36A9E1',
        },
        inline: {
          100: '#F6FFDC',
          200: '#7AA40B',
        },
        beeline: {
          100: '#FFEFC6',
          200: '#B48713',
        },
        frontline: {
          100: '#FFE7EE',
          200: '#A80030',
        },
      },
      'social-button': {
        facebook: '#1877F2',
        linkedin: '#0A66C2',
      },
    },
    fontFamily: {
      'inter-var': ['"Inter var"', ...sans],
      inter: ['"Inter var"', ...sans],
      ['noto-sans']: ['"Noto Sans Thai"', ...defaultTheme.fontFamily.sans],
    },
    extend: {
      boxShadow: {
        DEFAULT: '0px 6px 16px rgba(35, 44, 52, 0.06)',
        sm: '0px 6px 16px rgba(35, 44, 52, 0.06)',
        md: '0px 20px 40px rgba(35, 44, 52, 0.12)',
      },
      backdropBlur: {
        DEFAULT: '42px',
      },
      borderWidth: {
        DEFAULT: '1px',
        0: '0px',
        2: '2px',
        3: '3px',
      },
      borderColor: (theme) => ({
        DEFAULT: theme('colors.gray.300', 'currentColor'),
        black: theme('colors.black', 'currentColor'),
        'brand-primary': theme('colors.brand.primary', 'currentColor'),
        'gray-700': theme('colors.gray.700', 'currentColor'),
        'gray-500': theme('colors.gray.500', 'currentColor'),
        'gray-300': theme('colors.gray.300', 'currentColor'),
        'gray-200': theme('colors.gray.200', 'currentColor'),
        'gray-100': theme('colors.gray.100', 'currentColor'),
        'red-200': theme('colors.red.200', 'currentColor'),
        'maroon-600': theme('colors.maroon.600', 'currentColor'),
      }),
      fontWeight: {
        regular: '400',
        medium: '500',
        semibold: '600',
        bold: '700',
      },
      lineHeight: {
        none: '1',
        tight: '1.25',
        snug: '1.375',
        normal: '1.5',
        relaxed: '1.625',
        loose: '2',
        3: '.75rem',
        4: '1rem',
        5: '1.25rem',
        6: '1.5rem',
        7: '1.75rem',
        8: '2rem',
        9: '2.25rem',
        10: '2.5rem',
      },
      fontSize: {
        footnote: ['0.75rem', { lineHeight: '1rem' }],
        caption: ['0.875rem', { lineHeight: '1.25rem' }],
        body: ['1rem', { lineHeight: '1.625rem' }],
        subheading: ['1.125rem', { lineHeight: '1.75rem' }],
        heading: ['1.25rem', { lineHeight: '1.75rem' }],
        subtitle: ['1.5rem', { lineHeight: '2rem' }],
        'title-mobile': ['1.75rem', { lineHeight: '2.25rem' }],
        'title-desktop': ['1.875rem', { lineHeight: '2.5rem' }],
        'hero-mobile': ['2.25rem', { lineHeight: '3rem' }],
        'hero-desktop': ['2.5rem', { lineHeight: '3.375rem' }],
        'title-certificate': ['0.5rem', { lineHeight: '0.5rem' }],
        // --V2 font size--
        display1: ['4.5rem', { lineHeight: '5rem' }],
        display2: ['3.75rem', { lineHeight: '4.5rem' }],
        h1: ['3rem', { lineHeight: '3.5rem' }],
        h1s: ['2.125rem', { lineHeight: '2.5rem' }],
        h2: ['2.4375rem', { lineHeight: '2.9375rem' }],
        h2s: ['2.0625rem', { lineHeight: '2.5rem' }],
        h3: ['2.0625rem', { lineHeight: '2.5rem' }],
        h3s: ['1.75rem', { lineHeight: '2.125rem' }],
        h4: ['1.75rem', { lineHeight: '2.125rem' }],
        h4s: ['1.4375rem', { lineHeight: '1.75rem' }],
        h5: ['1.4375rem', { lineHeight: '1.75rem' }],
        h5s: ['1.1875rem', { lineHeight: '1.4375rem' }],
        h6: ['1.1875rem', { lineHeight: '1.4375rem' }],
        h6s: ['1rem', { lineHeight: '1.25rem' }],
        subheadingv2: ['1.25rem', { lineHeight: '1.5rem' }],
        p1: ['0.875rem', { lineHeight: '1.25rem' }],
        p2: ['1rem', { lineHeight: '1.5rem' }],
        p3: ['1.125rem', { lineHeight: '1.5rem' }],
        captionv2: ['0.75rem', { lineHeight: '0.875rem' }],
        // --End of V2 font size--
      },
      spacing: {
        px: '1px',
        0: '0px',
        1: '0.25rem',
        2: '0.5rem',
        3: '0.75rem',
        3.5: '0.875rem',
        4: '1rem', // 16px
        5: '1.25rem',
        6: '1.5rem',
        8: '2rem',
        10: '2.5rem',
        12: '3rem',
        13: '3.25rem',
        14: '3.5rem',
        15: '3.75rem',
        16: '4rem',
        18: '4.5rem',
        20: '5rem',
        22: '5.5rem',
        24: '6rem',
        24.5: '6.25rem',
        25: '6.5rem',
        25.5: '7rem',
        26: '7.5rem',
        27: '8rem',
        28: '9rem',
        29: '9.25rem',
        55: '13.75rem',
        80: '20rem',
        92: '30.5rem',
        100: '40rem',
        256: '64rem',
        // Needed as per design
        '1px': '1px',
        '14px': '14px',
        '20px': '20px',
        '40px': '40px',
        '46px': '46px',
        '50px': '50px',
        '51px': '51px',
        '55px': '55px',
        '72px': '72px',
        '96px': '96px',
        '120px': '120px',
        '192px': '192px',
        '210px': '210px',
        '230px': '230px',
        '248px': '248px',
        '275px': '275px',
        '400px': '400px',
        '416px': '416px',
        '472px': '472px',
        '344px': '344px',
        '348px': '348px',
        '524px': '524px',
        '724px': '724px',
        '78vh': '78vh',
        85: '21.25rem',
        88: '27.5rem',
        136: '34rem',
        140: '35rem',
        179: '44.75rem',
        180: '45rem',
        181: '45.25rem',
        244: '61rem',
        278: '69.5rem',
        299: '74.75rem',
        fit: 'fit-content',
      },
      padding: {
        full: '100%',
        '127%': '127%',
      },

      minWidth: {
        px: '1px',
        10: '2.5rem',
        22: '5.5rem',
        36: '9rem',
        40: '10rem',
        48: '12rem',
        64: '16rem',
        256: '64rem',
        '24px': '24px',
        '116px': '116px',
        '384px': '384px',
        '476px': '476px',
        '592px': '592px',
      },
      maxWidth: {
        0: '0rem',
        40: '10rem',
        48: '12rem',
        64: '16rem',
        82: '20.5rem',
        299: '74.75rem',
        '416px': '416px',
        '524px': '524px',
        '768px': '768px',
        '1112px': '1112px',
        '1440px': '1440px',
      },
      minHeight: {
        6: '1.5rem',
        10: '2.5rem',
        22: '5.5rem',
        52: '13rem',
        168: '42rem',
      },
      maxHeight: {
        '1/2vh': '50vh',
        168: '42rem',
      },
      backgroundImage: () => ({
        'checked-dropdown':
          "url(\"data:image/svg+xml;utf8,<svg width='14' height='11' viewBox='0 0 14 11' fill='black' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.7071 0.642503C14.0976 1.03303 14.0976 1.66619 13.7071 2.05672L5.70711 10.0567C5.31658 10.4472 4.68342 10.4472 4.29289 10.0567L0.292893 6.05672C-0.0976311 5.66619 -0.0976311 5.03303 0.292893 4.6425C0.683418 4.25198 1.31658 4.25198 1.70711 4.6425L5 7.9354L12.2929 0.642503C12.6834 0.251978 13.3166 0.251978 13.7071 0.642503Z' fill='%23A80030'/></svg>\")",
        'select-dropdown':
          "url(\"data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>\")",
        card: `url(/assets/card-bg.svg)`,
      }),
      ringOffsetWidth: {
        3: '3px',
      },
      objectPosition: {
        'center-top': 'center top',
      },
      gridTemplateColumns: {
        '7-7': 'repeat(7, 1.75rem)',
        '7-11': 'repeat(7, 2.75rem)',
        '7-auto': 'repeat(7, auto)',
      },
      gridAutoRows: {
        7: '1.75rem',
        11: '2.75rem',
      },
      zIndex: {
        '-1': '-1',
        1: '1',
        2: '2',
        60: '60',
      },
      inset: {
        8: '8rem',
        '8.25rem': '8.25rem',
        10.5: '10.5rem',
        11: '11rem',
        14: '14rem',
        15.25: '15.25rem',
        20.5: '20.5rem',
      },
    },
  },
  plugins: [require('@tailwindcss/aspect-ratio')],
};
