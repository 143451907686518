import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.67871 22.75C5.02186 22.75 3.67871 21.4069 3.67871 19.75V5.75C3.67871 4.09315 5.02186 2.75 6.67871 2.75H14.8503C15.6459 2.75 16.409 3.06607 16.9716 3.62868L20.8 7.45711C21.3626 8.01972 21.6787 8.78278 21.6787 9.57843V19.75C21.6787 21.4069 20.3356 22.75 18.6787 22.75H6.67871Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.67871 13.75C7.67871 13.1977 8.12643 12.75 8.67871 12.75H16.6787C17.231 12.75 17.6787 13.1977 17.6787 13.75C17.6787 14.3023 17.231 14.75 16.6787 14.75H8.67871C8.12643 14.75 7.67871 14.3023 7.67871 13.75ZM7.67871 17.75C7.67871 17.1977 8.12643 16.75 8.67871 16.75H12.6787C13.231 16.75 13.6787 17.1977 13.6787 17.75C13.6787 18.3023 13.231 18.75 12.6787 18.75H8.67871C8.12643 18.75 7.67871 18.3023 7.67871 17.75Z"
        fill="#ECEDED"
      />
    </svg>
  );
}

export default SvgComponent;
