import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.59698 13.1285L4.04415 15.6216C4.21665 15.9187 4.57598 16.0534 4.90266 15.9435L7.03212 15.2269C7.2203 15.3503 7.41543 15.4626 7.61625 15.5631L8.05696 17.7548C8.12459 18.0912 8.4216 18.3333 8.7665 18.3333H11.6608C12.0058 18.3333 12.3028 18.0912 12.3704 17.7548L12.8111 15.5631C13.0119 15.4626 13.207 15.3503 13.3952 15.2269L15.5247 15.9435C15.8514 16.0533 16.2107 15.9187 16.3832 15.6216L17.8304 13.1284C18.0028 12.8313 17.9405 12.4545 17.6814 12.228L15.9926 10.7518C15.9991 10.64 16.0024 10.528 16.0024 10.4167C16.0024 10.3053 15.9991 10.1933 15.9926 10.0815L17.6813 8.60533C17.9404 8.37887 18.0027 8.00199 17.8303 7.7049L16.3831 5.21177C16.2107 4.91468 15.8513 4.7799 15.5246 4.88987L13.3952 5.6064C13.207 5.483 13.0119 5.37073 12.8111 5.27026L12.3704 3.07854C12.3028 2.74215 12.0058 2.5 11.6608 2.5H8.7665C8.4216 2.5 8.12459 2.74215 8.05696 3.07854L7.61625 5.27026C7.41543 5.37073 7.22035 5.48305 7.03217 5.6064L4.90266 4.88987C4.57608 4.78 4.21665 4.91468 4.04415 5.21177L2.59698 7.7049C2.42452 8.00199 2.48685 8.37887 2.74594 8.60533L4.43469 10.0815C4.42823 10.1932 4.425 10.3051 4.425 10.4167C4.425 10.5282 4.42823 10.6402 4.43469 10.7519L2.74594 12.228C2.48685 12.4545 2.42452 12.8313 2.59698 13.1285ZM10.2137 7.53788C11.8096 7.53788 13.108 8.8293 13.108 10.4167C13.108 12.004 11.8096 13.2955 10.2137 13.2955C8.61774 13.2955 7.31933 12.004 7.31933 10.4167C7.31933 8.8293 8.61774 7.53788 10.2137 7.53788Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
