import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.33325 3.23349C5.87011 2.55212 3.65269 2.19046 1.57801 3.88623C1.42311 4.01284 1.33325 4.20234 1.33325 4.40241V12.6669C1.33325 12.9244 1.48159 13.1589 1.71428 13.2693C1.94698 13.3796 2.22242 13.3461 2.42182 13.1831C4.24622 11.6919 6.24466 12.3091 7.33325 13.0129V3.23349Z"
        fill="#C1C2C4"
      />
      <path
        d="M8.66659 3.23349V13.0129C9.75518 12.3091 11.7536 11.6919 13.578 13.1831C13.7774 13.3461 14.0529 13.3796 14.2856 13.2693C14.5183 13.1589 14.6666 12.9244 14.6666 12.6669V4.40241C14.6666 4.20234 14.5767 4.01284 14.4218 3.88623C12.3471 2.19046 10.1297 2.55212 8.66659 3.23349Z"
        fill="#C1C2C4"
      />
    </svg>
  );
}

export default SvgComponent;
