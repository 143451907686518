import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1.66663C10.4603 1.66663 10.8334 2.03972 10.8334 2.49996V3.37916C12.6453 3.58469 13.8874 4.45897 14.6763 5.55471C15.5502 6.76846 15.8334 8.19994 15.8334 9.16663V12.1548L17.2559 13.5774C17.4943 13.8157 17.5656 14.1741 17.4366 14.4855C17.3076 14.7969 17.0037 15 16.6667 15L14.1667 15L13.3334 15H6.66669L5.83336 15L3.33336 15C2.99631 15 2.69244 14.7969 2.56346 14.4855C2.43447 14.1741 2.50577 13.8157 2.7441 13.5774L4.16669 12.1548V9.16663C4.16669 6.89503 5.13186 5.39485 6.3881 4.49035C7.28731 3.84292 8.30601 3.51973 9.16669 3.3957V2.49996C9.16669 2.03972 9.53979 1.66663 10 1.66663ZM6.7716 15.8333C6.86889 16.2304 7.04197 16.658 7.32375 17.0494C7.85324 17.7848 8.72842 18.3333 10 18.3333C11.2716 18.3333 12.1468 17.7848 12.6763 17.0494C12.9581 16.658 13.1312 16.2304 13.2284 15.8333H6.7716Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
