import { SVGProps } from 'react';

function SvgComponent(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.521 2.83325H9.479C9.40499 2.54571 9.14397 2.33325 8.83333 2.33325H7.16667C6.85603 2.33325 6.59501 2.54571 6.521 2.83325ZM4.5 2.99992C4.5 1.52716 5.69391 0.333252 7.16667 0.333252H8.83333C10.3061 0.333252 11.5 1.52716 11.5 2.99992H14.6667C15.1269 2.99992 15.5 3.37301 15.5 3.83325C15.5 4.29349 15.1269 4.66658 14.6667 4.66658H13.8333V12.9999C13.8333 14.3806 12.714 15.4999 11.3333 15.4999H4.66667C3.28595 15.4999 2.16667 14.3806 2.16667 12.9999V4.66658H1.33333C0.873096 4.66658 0.5 4.29349 0.5 3.83325C0.5 3.37301 0.873096 2.99992 1.33333 2.99992H4.5ZM7.16667 7.16658C7.16667 6.70635 6.79357 6.33325 6.33333 6.33325C5.8731 6.33325 5.5 6.70635 5.5 7.16658V11.3333C5.5 11.7935 5.8731 12.1666 6.33333 12.1666C6.79357 12.1666 7.16667 11.7935 7.16667 11.3333V7.16658ZM10.5 7.16658C10.5 6.70635 10.1269 6.33325 9.66667 6.33325C9.20643 6.33325 8.83333 6.70635 8.83333 7.16658V11.3333C8.83333 11.7935 9.20643 12.1666 9.66667 12.1666C10.1269 12.1666 10.5 11.7935 10.5 11.3333V7.16658Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
