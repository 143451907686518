import { useContext, useEffect } from 'react';
import cookie from 'cookie';
import { endOfDay } from 'date-fns';
import gamificationApi from '@http/gamification';

import { IAuthContext } from './authContext';
import { GamificationContext } from './gamificationContext';

const COOKIE_NAME = 'GAMIFICATION_LOGIN';

function getGamificationCookieName(userId: string) {
  return `${COOKIE_NAME}_${userId}`;
}

function setGamificationUserLoginCookie(userId: string) {
  const cookieName = getGamificationCookieName(userId);
  document.cookie = cookie.serialize(cookieName, String(new Date().valueOf()), {
    expires: endOfDay(new Date()),
  });
}

function getGamificationUserLoginCookie(userId: string): string | null {
  const cookies = cookie.parse(document.cookie);
  const cookieName = getGamificationCookieName(userId);
  return cookies[cookieName];
}

async function callGamificationLoginApiEndpoint() {
  await gamificationApi.userLogin();
}

function useGamification(token: IAuthContext['token']) {
  const { updateGamificationStatsChange } = useContext(GamificationContext);

  useEffect(() => {
    async function callEndpointAndSetCookie(userId: string) {
      await callGamificationLoginApiEndpoint();
      setGamificationUserLoginCookie(userId);
      updateGamificationStatsChange();
    }

    if (token && token.user && token.user.id) {
      const gamificationCookie = getGamificationUserLoginCookie(token.user.id);
      if (!gamificationCookie) {
        callEndpointAndSetCookie(token.user.id);
      }
    }
  }, [token, updateGamificationStatsChange]);
}

export default useGamification;
