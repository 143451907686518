import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.67871 12.75C3.67871 7.77944 7.70815 3.75 12.6787 3.75C17.6493 3.75 21.6787 7.77944 21.6787 12.75C21.6787 17.7206 17.6493 21.75 12.6787 21.75C7.70815 21.75 3.67871 17.7206 3.67871 12.75ZM13.5787 8.25C13.5787 7.75294 13.1758 7.35 12.6787 7.35C12.1817 7.35 11.7787 7.75294 11.7787 8.25V11.6375C11.7787 12.6602 12.3565 13.5951 13.2712 14.0525L15.8762 15.355C16.3208 15.5773 16.8614 15.3971 17.0837 14.9525C17.306 14.5079 17.1258 13.9673 16.6812 13.745L14.0762 12.4425C13.7713 12.2901 13.5787 11.9784 13.5787 11.6375V8.25Z"
        fill="#C1C2C4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6787 7.3501C13.1757 7.3501 13.5787 7.75304 13.5787 8.2501V11.6376C13.5787 11.9785 13.7713 12.2902 14.0762 12.4426L16.6812 13.7451C17.1258 13.9674 17.306 14.508 17.0837 14.9526C16.8614 15.3972 16.3208 15.5774 15.8762 15.3551L13.2712 14.0526C12.3565 13.5952 11.7787 12.6603 11.7787 11.6376V8.2501C11.7787 7.75304 12.1816 7.3501 12.6787 7.3501Z"
        fill="#6A6B6E"
      />
    </svg>
  );
}

export default SvgComponent;
