import { SVGProps } from 'react';

function SvgComponent(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={17}
      height={16}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.20276 1.56298C7.95027 0.925948 9.04971 0.925948 9.79723 1.56298L10.6449 2.28533C10.7516 2.37633 10.8844 2.43132 11.0243 2.44248L12.1344 2.53107C13.1134 2.6092 13.8909 3.38662 13.969 4.36564L14.0576 5.4758C14.0687 5.61564 14.1237 5.7484 14.2147 5.85518L14.9371 6.70282C15.5741 7.45034 15.5741 8.54978 14.9371 9.29729L14.2147 10.1449C14.1237 10.2517 14.0687 10.3845 14.0576 10.5243L13.969 11.6345C13.8909 12.6135 13.1134 13.3909 12.1344 13.469L11.0243 13.5576C10.8844 13.5688 10.7516 13.6238 10.6449 13.7148L9.79723 14.4371C9.04971 15.0742 7.95027 15.0742 7.20276 14.4371L6.35511 13.7148C6.24834 13.6238 6.11558 13.5688 5.97574 13.5576L4.86557 13.469C3.88656 13.3909 3.10913 12.6135 3.03101 11.6345L2.94242 10.5243C2.93126 10.3845 2.87627 10.2517 2.78527 10.1449L2.06291 9.29729C1.42589 8.54978 1.42589 7.45034 2.06291 6.70282L2.78527 5.85518C2.87627 5.7484 2.93126 5.61564 2.94242 5.4758L3.03101 4.36564C3.10913 3.38662 3.88656 2.6092 4.86557 2.53107L5.97574 2.44248C6.11558 2.43132 6.24834 2.37633 6.35511 2.28533L7.20276 1.56298ZM10.9714 7.13811C11.2317 6.87776 11.2317 6.45565 10.9714 6.1953C10.711 5.93495 10.2889 5.93495 10.0286 6.1953L7.83332 8.39056L6.97139 7.52864C6.71105 7.26829 6.28894 7.26829 6.02859 7.52864C5.76824 7.78899 5.76824 8.2111 6.02859 8.47145L7.36192 9.80478C7.62227 10.0651 8.04438 10.0651 8.30473 9.80478L10.9714 7.13811Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
