import React from 'react';
import { ClockIcon } from '@heroicons/react/solid';

const FullscreenLoader = () => {
  return (
    <div className="bg-gray-800 fixed inset-0 flex items-center justify-center bg-opacity-50">
      {/* Centered loader */}
      <div className="flex items-center space-x-2 rounded-lg bg-white p-4 shadow-lg">
        <ClockIcon className="h-6 w-6 animate-spin" />
        <span>Loading...</span>
      </div>
    </div>
  );
};

export default FullscreenLoader;
