import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8327 6.24984C10.8327 5.7896 10.4596 5.4165 9.99935 5.4165C9.53911 5.4165 9.16602 5.7896 9.16602 6.24984V7.08317C9.16602 7.54341 9.53911 7.9165 9.99935 7.9165C10.4596 7.9165 10.8327 7.54341 10.8327 7.08317V6.24984Z"
        fill="currentColor"
      />
      <path
        d="M4.75527 7.32725C4.42983 7.00181 3.9022 7.00181 3.57676 7.32725C3.25132 7.65269 3.25132 8.18032 3.57676 8.50576L4.41009 9.33909C4.73553 9.66453 5.26317 9.66453 5.5886 9.33909C5.91404 9.01366 5.91404 8.48602 5.5886 8.16058L4.75527 7.32725Z"
        fill="currentColor"
      />
      <path
        d="M16.4219 7.32725C16.0965 7.00181 15.5689 7.00181 15.2434 7.32725L14.4101 8.16058C14.0847 8.48602 14.0847 9.01366 14.4101 9.33909C14.7355 9.66453 15.2632 9.66453 15.5886 9.33909L16.4219 8.50576C16.7474 8.18032 16.7474 7.65269 16.4219 7.32725Z"
        fill="currentColor"
      />
      <path
        d="M1.66602 13.7498C1.66602 13.2896 2.03911 12.9165 2.49935 12.9165H5.91603C6.30209 11.0146 7.98355 9.58317 9.99935 9.58317C12.0151 9.58317 13.6966 11.0146 14.0827 12.9165H17.4993C17.9596 12.9165 18.3327 13.2896 18.3327 13.7498C18.3327 14.2101 17.9596 14.5832 17.4993 14.5832H14.0827H5.91603H2.49935C2.03911 14.5832 1.66602 14.2101 1.66602 13.7498Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
