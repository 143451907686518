import { useRouter } from 'next/router';
import { IntlProvider } from 'react-intl';
import API_PATHS from '@constants/apiPaths';
import { centralHttp } from '../http';

import { useEffect, useState } from 'react';
import { LanguageCode } from '@models/language';
import { setNextLocaleCookie } from './lang-utils';
import Cookies from 'js-cookie';
import FullscreenLoader from '../ui-kit/FullScreenLoader';

export interface TranslationData {
  [languageKey: string]: Record<string, string>;
}
export interface TranslationApiResponseData {
  config: {
    [languageKey: string]: {
      isActive: boolean;
      languageFullName: string;
      priority: string;
      languageCode: LanguageCode;
      isDefault: boolean;
    };
  };
  translations: TranslationData;
}

// Translation service function
export async function fetchTranslations(
  shouldFetchAllConfigured: boolean,
): Promise<TranslationApiResponseData> {
  const translations = await centralHttp.get<any>(
    shouldFetchAllConfigured
      ? API_PATHS.LANGUAGE_TRANSLATIONS_CONFIGURED
      : API_PATHS.LANGUAGE_TRANSLATIONS,
  );
  return translations.data.data as TranslationApiResponseData;
}

// Custom hook to load translations
export function useTranslations(shouldFetchAllConfigured = false) {
  const [translationResponse, setTranslationResponse] =
    useState<TranslationApiResponseData | null>(null);

  useEffect(() => {
    fetchTranslations(shouldFetchAllConfigured)
      .then((data: TranslationApiResponseData) => setTranslationResponse(data))
      .catch((error) => {
        // Handle error while fetching translations
        console.error('Error fetching translations:', error);
      });
  }, [shouldFetchAllConfigured]);

  return translationResponse;
}

const Provider = ({ children }) => {
  let defaultLocale;

  const router = useRouter();
  const { locale: routerLocale } = router;
  const userPreferredLocale = Cookies.get('NEXT_LOCALE');

  if (userPreferredLocale && userPreferredLocale !== routerLocale) {
    router.locale = userPreferredLocale;
  }

  const data = useTranslations();

  if (!data?.translations || !data?.config) {
    // Wait for translations to load
    return <FullscreenLoader />;
  }
  const defaultLocaleObj = Object.entries(data.config).find(
    ([, value]) => value.isDefault,
  );

  if (Array.isArray(defaultLocaleObj) && defaultLocaleObj.length) {
    defaultLocale = defaultLocaleObj[0];
  }

  if (!userPreferredLocale) {
    router.locale = defaultLocale;
  }

  const isUserPreferredLocaleInConfig =
    !!userPreferredLocale &&
    !!Object.entries(data.config).find(
      ([, value]) => value.languageCode === userPreferredLocale,
    )?.length;

  if (!isUserPreferredLocaleInConfig) {
    setNextLocaleCookie(defaultLocale);
  }

  const locale = !isUserPreferredLocaleInConfig
    ? defaultLocale
    : userPreferredLocale;

  const messages = data.translations[locale];

  const flattenedMessages = {};

  function flattenKeys(data, parentKey = '') {
    for (const key in data) {
      const newKey = parentKey ? `${parentKey}.${key}` : key;
      const value = data[key];

      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          if (typeof value[i] === 'object' && value[i] !== null) {
            flattenKeys(value[i], `${newKey}.${i}`);
          } else {
            flattenedMessages[`${newKey}.${i}`] = value[i];
          }
        }
      } else if (typeof value === 'object' && value !== null) {
        flattenKeys(value, newKey);
      } else {
        flattenedMessages[newKey] = value;
      }
    }
  }

  flattenKeys(messages);

  return (
    <IntlProvider
      locale={locale}
      defaultLocale={defaultLocale}
      messages={flattenedMessages}
    >
      {children}
    </IntlProvider>
  );
};

export default Provider;
