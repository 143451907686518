import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66667 2V3.33333H3.33333V12.6667H12.6667V9.33333H14V13.3333C14 13.5101 13.9298 13.6797 13.8047 13.8047C13.6797 13.9298 13.5101 14 13.3333 14H2.66667C2.48986 14 2.32029 13.9298 2.19526 13.8047C2.07024 13.6797 2 13.5101 2 13.3333V2.66667C2 2.48986 2.07024 2.32029 2.19526 2.19526C2.32029 2.07024 2.48986 2 2.66667 2H6.66667ZM11.724 3.33333H8.66667V2H14V7.33333H12.6667V4.276L8 8.94267L7.05733 8L11.724 3.33333Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
