import * as React from 'react';

function SvgCompany(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.974 14.798h-.38V2.202a.53.53 0 00-.526-.535H3.933c-.291 0-.527.24-.527.535v12.596h-.379c-.29 0-.526.24-.526.536v2.464c0 .295.236.535.526.535h4.87v-4.652c0-.296.235-.536.526-.536h3.156c.291 0 .527.24.527.535v4.631l-.002.022h4.87c.292 0 .527-.24.527-.535v-2.464a.53.53 0 00-.526-.536zm-9.49-3.881a.531.531 0 01-.527.535H5.238a.531.531 0 01-.526-.535v-1.75c0-.296.236-.536.526-.536h1.72c.29 0 .525.24.525.536v1.75zm0-4.179a.531.531 0 01-.527.536H5.238a.531.531 0 01-.526-.536v-1.75c0-.296.236-.536.526-.536h1.72c.29 0 .525.24.525.536v1.75zm3.902 4.179a.531.531 0 01-.526.535H9.14a.531.531 0 01-.526-.535v-1.75c0-.296.236-.536.527-.536h1.719c.29 0 .526.24.526.536v1.75zm0-4.179a.531.531 0 01-.526.536H9.14a.531.531 0 01-.526-.536v-1.75c0-.296.236-.536.527-.536h1.719c.29 0 .526.24.526.536v1.75zm3.902 4.179a.531.531 0 01-.526.535h-1.719a.531.531 0 01-.526-.535v-1.75c0-.296.236-.536.526-.536h1.719c.29 0 .526.24.526.536v1.75zm0-4.179a.531.531 0 01-.526.536h-1.719a.531.531 0 01-.526-.536v-1.75c0-.296.236-.536.526-.536h1.719c.29 0 .526.24.526.536v1.75z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCompany;
