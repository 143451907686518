import * as React from 'react';

function SvgAccount(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5144 3.12584C11.8164 2.95805 12.1836 2.95805 12.4856 3.12584L21.4856 8.12584C21.8031 8.30221 22 8.63683 22 9V16C22 16.5523 21.5523 17 21 17C20.4477 17 20 16.5523 20 16V10.6995L19 11.2551V11.2591L12.9331 14.2749C12.3484 14.5656 11.6585 14.5525 11.0852 14.2398L2.35675 9.47885C2.26556 9.42911 2.17003 9.39505 2.07326 9.37575C2.02562 9.25827 2 9.13099 2 9C2 8.63683 2.19689 8.30221 2.51436 8.12584L11.5144 3.12584ZM5 13.1988V17C5 17.3788 5.214 17.725 5.55279 17.8944L11.5528 20.8944C11.8343 21.0352 12.1657 21.0352 12.4472 20.8944L18.4472 17.8944C18.786 17.725 19 17.3788 19 17V13.7437L12.9166 16.5884C12.3403 16.8579 11.6702 16.8371 11.1117 16.5325L5 13.1988Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgAccount;
