import * as React from 'react';

function SvgAccount(props) {
  return (
    <svg
      width={20}
      height={21}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66663 5.29297C1.66663 3.91226 2.78591 2.79297 4.16663 2.79297H15.8333C17.214 2.79297 18.3333 3.91226 18.3333 5.29297V6.1263C18.3333 6.42987 18.2521 6.71449 18.1103 6.95964H1.8896C1.74779 6.71449 1.66663 6.42987 1.66663 6.1263V5.29297ZM2.49996 8.6263V15.293C2.49996 16.6737 3.61925 17.793 4.99996 17.793H15C16.3807 17.793 17.5 16.6737 17.5 15.293V8.6263H2.49996ZM8.33329 10.293C7.87305 10.293 7.49996 10.6661 7.49996 11.1263C7.49996 11.5865 7.87305 11.9596 8.33329 11.9596H11.6666C12.1269 11.9596 12.5 11.5865 12.5 11.1263C12.5 10.6661 12.1269 10.293 11.6666 10.293H8.33329Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgAccount;
