import NEXT_API_PATHS from '@constants/nextApiPaths';
import axios from 'axios';

const gamificationApi = {
  userLogin: async (): Promise<any> => {
    await axios.post(NEXT_API_PATHS.GAMIFICATION);
  },
  me: async (): Promise<any> => {
    return await axios.get(NEXT_API_PATHS.GAMIFICATION);
  },
};

export default gamificationApi;
