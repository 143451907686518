import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.60451 2.69129C8.67601 2.38841 8.57277 2.07093 8.33681 1.86801C8.10085 1.6651 7.77149 1.61057 7.48273 1.72662C4.35154 2.98495 1.66602 5.77856 1.66602 9.55867C1.66602 14.4047 5.5945 18.3332 10.4405 18.3332C14.2206 18.3332 17.0142 15.6477 18.2726 12.5165C18.3861 12.234 18.3366 11.9122 18.1434 11.6769C17.9502 11.4417 17.6442 11.3304 17.345 11.3868C14.7546 11.875 12.8996 11.7723 11.5862 11.3484C10.2959 10.932 9.48869 10.1943 8.9828 9.30309C7.93019 7.4488 8.1092 4.78967 8.60451 2.69129Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
