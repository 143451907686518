import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.41667 2.1665C7.8769 2.1665 8.25 2.5396 8.25 2.99984V3.83317H13.25V2.99984C13.25 2.5396 13.6231 2.1665 14.0833 2.1665C14.5436 2.1665 14.9167 2.5396 14.9167 2.99984V3.83317H15.75C17.1307 3.83317 18.25 4.95246 18.25 6.33317V7.1665H3.25V6.33317C3.25 4.95246 4.36929 3.83317 5.75 3.83317H6.58333V2.99984C6.58333 2.5396 6.95643 2.1665 7.41667 2.1665Z"
        fill="#888A8D"
      />
      <path
        d="M3.25 8.83398V16.334C3.25 17.7147 4.36929 18.834 5.75 18.834H15.75C17.1307 18.834 18.25 17.7147 18.25 16.334V8.83398H3.25Z"
        fill="#C1C2C4"
      />
    </svg>
  );
}

export default SvgComponent;
