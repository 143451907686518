import { SVGProps } from 'react';

function SvgComponent(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        id="Icon"
        d="M14.6663 6.6823H7.99967C6.52692 6.6823 5.33301 7.8762 5.33301 9.34896V24.0156C5.33301 25.4884 6.52692 26.6823 7.99967 26.6823H22.6663C24.1391 26.6823 25.333 25.4884 25.333 24.0156V17.349M23.4474 4.79667C24.4888 3.75528 26.1772 3.75528 27.2186 4.79667C28.26 5.83807 28.26 7.52651 27.2186 8.56791L15.7709 20.0156H11.9997L11.9997 16.2444L23.4474 4.79667Z"
        stroke="currentColor"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
