const config = Object.freeze({
  AUTH_API_BASE_URL: process.env.NEXT_PUBLIC_AUTH_API_BASE_URL,
  CENTRAL_API_BASE_URL: process.env.NEXT_PUBLIC_CENTRAL_API_BASE_URL,
  PAYMENT_API_BASE_URL: process.env.NEXT_PUBLIC_PAYMENT_API_BASE_URL,
  NOTIFICATION_API_BASE_URL: process.env.NEXT_PUBLIC_NOTIFICATION_API_BASE_URL,
  CORPORATE_WEB_BASE_URL: process.env.NEXT_PUBLIC_CORPORATE_WEB_BASE_URL,
  CDN_BASE_URL: process.env.NEXT_PUBLIC_CDN_BASE_URL,
  RECAPTCHA_SITE_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
  NOTIFICATION_URL: process.env.NEXT_PUBLIC_NOTIFICATION_URL,
  NOTIFICATION_BASE_URL: process.env.NEXT_PUBLIC_NOTIFICATION_API_BASE_URL,
  FACEBOOK_PAGE_ID: process.env.NEXT_PUBLIC_FACEBOOK_PAGE_ID,
  SUPPORT_LANGUAGES:
    process.env.NEXT_PUBLIC_SUPPORT_LANGUAGES?.split(',') || [],
  GLOBAL_CURRENCY: process.env.NEXT_PUBLIC_GLOBAL_CURRENCY,
  DEFAULT_COURSE_IMAGE_SRC: '/assets/course/course-default.png',
  YNU_PACKAGE_PAGE: 'https://www.yournextu.com/professional/',
  DEFAULT_IMAGE_V2_SRC: '/assets/card-default-image.png',
  IS_PRODUCTION: process.env.NEXT_PUBLIC_IS_PRODUCTION === 'true',
  DATADOG_APP_ID: process.env.NEXT_PUBLIC_DATADOG_APP_ID,
  DATADOG_TOKEN: process.env.NEXT_PUBLIC_DATADOG_TOKEN,
});

export default config;
