import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 8.99992H5.08013C5.66398 8.99992 6.21868 8.74479 6.59864 8.3015L10.2407 4.05238C10.8449 3.34748 12 3.77477 12 4.70317V19.2967C12 20.2251 10.8449 20.6523 10.2407 19.9474L6.59864 15.6983C6.21868 15.255 5.66398 14.9999 5.08013 14.9999H5C3.89543 14.9999 3 14.1045 3 12.9999V10.9999C3 9.89535 3.89543 8.99992 5 8.99992Z"
        fill="#C1C2C4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2226 4.31128C17.603 3.91086 18.2359 3.8946 18.6363 4.27497C23.1212 8.53522 23.1212 15.4648 18.6363 19.725C18.2359 20.1054 17.603 20.0891 17.2226 19.6887C16.8422 19.2883 16.8585 18.6553 17.2589 18.275C20.9137 14.8032 20.9137 9.19677 17.2589 5.72503C16.8585 5.34466 16.8422 4.71171 17.2226 4.31128ZM14.275 7.11129C14.6553 6.71086 15.2883 6.6946 15.6887 7.07497C18.5457 9.78883 18.5457 14.2112 15.6887 16.925C15.2883 17.3054 14.6553 17.2891 14.275 16.8887C13.8946 16.4883 13.9109 15.8553 14.3113 15.475C16.3381 13.5496 16.3381 10.4504 14.3113 8.52503C13.9109 8.14466 13.8946 7.51171 14.275 7.11129Z"
        fill="#6A6B6E"
      />
    </svg>
  );
}

export default SvgComponent;
