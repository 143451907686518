import gamificationApi from '@http/gamification';
import { createContext, FC, Fragment, useContext } from 'react';
import useSWR, { KeyedMutator } from 'swr';
import { AuthContext } from './authContext';
import useGamification from './useGamification';

interface IGamificationContext {
  level: number | null;
  points: number | null;
  updateGamificationStatsChange: KeyedMutator<IGamificationMeResponse>;
}

export const GamificationContext =
  createContext<IGamificationContext>(undefined);

interface IGamificationMeResponse {
  data: {
    userPoint?: {
      point: number;
      level: number;
    };
  };
}

const GamificationWrapper: FC = (props) => {
  const { token } = useContext(AuthContext);
  useGamification(token);
  return <Fragment>{props.children}</Fragment>;
};

export const GamificationContextProvider: FC = (props) => {
  const { token } = useContext(AuthContext);
  const { data, mutate } = useSWR<IGamificationMeResponse>(
    ['USER_POINTS', token?.user?.id],
    async () => {
      if (token) {
        return await gamificationApi.me();
      }
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  return (
    <GamificationContext.Provider
      value={{
        level: data?.data?.userPoint?.level,
        points: data?.data?.userPoint?.point,
        updateGamificationStatsChange: mutate,
      }}
    >
      <GamificationWrapper>{props.children}</GamificationWrapper>
    </GamificationContext.Provider>
  );
};
