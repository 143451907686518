import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.91301 7.02868C7.75078 5.77217 9.62609 4 12.5 4C14.6295 4 16.1507 5.07973 17.1069 6.33005C17.7761 7.20524 18.2051 8.20886 18.3919 9.07502C19.1771 9.20223 20.0868 9.50524 20.8968 10.0885C22.0918 10.9489 23 12.3741 23 14.5C23 16.6259 22.0918 18.0511 20.8968 18.9115C19.7528 19.7352 18.41 20 17.5 20H7.5C6.42331 20 4.83054 19.6852 3.4782 18.7115C2.07487 17.7011 1 16.0259 1 13.5C1 10.9741 2.07487 9.29886 3.4782 8.28847C4.60179 7.47948 5.89136 7.12534 6.91301 7.02868ZM13 9.99989C13 9.44761 12.5523 8.99989 12 8.99989C11.4477 8.99989 11 9.44761 11 9.99989V14.5857L9.70713 13.2928C9.31661 12.9023 8.68344 12.9023 8.29292 13.2928C7.9024 13.6833 7.9024 14.3165 8.29292 14.707L11.2929 17.707C11.6834 18.0975 12.3166 18.0975 12.7071 17.707L15.7071 14.707C16.0977 14.3165 16.0977 13.6833 15.7071 13.2928C15.3166 12.9023 14.6834 12.9023 14.2929 13.2928L13 14.5857V9.99989Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
