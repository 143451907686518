import cx from 'classnames';
import { ToastProps } from 'react-toast-notifications';
import { isFunction } from 'lodash';

function SnackBarV2(toastProps: ToastProps & { snackClassName?: string }) {
  const {
    children,
    transitionState,
    onMouseEnter,
    onMouseLeave,
    snackClassName,
  } = toastProps;

  return (
    <div
      className={cx(' self-center', snackClassName, {
        'opacity-0 transition-opacity duration-500':
          transitionState === 'exiting',
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {isFunction(children) ? children(toastProps) : children}
    </div>
  );
}

export default SnackBarV2;
