export interface Language {
  id?: string;
  nameEn: string;
  nameTh: string;
  nameVi: string;
}

export interface LanguageMapping {
  id?: string;
  isActive: boolean;
  languageCode: string;
  languageFullName: string;
  priority: number;
  isConfigured: boolean;
  referenceLanguageName: string;
  languageColumnName: string;
  isDefault: boolean;
}

export const languageOptions = {
  en: 'English (EN)',
  th: 'ภาษาไทย (TH)',
  vi: 'Vietnamese (VI)',
};

export const languageOptionsCode = {
  en: 'en',
  th: 'th',
  vi: 'vi',
};

// todo: check this
export enum LanguageCode {
  EN = 'en',
  TH = 'th',
  VI = 'vi',
}

export enum LanguageCapitalized {
  EN = 'En',
  TH = 'Th',
  VI = 'Vi',
}
