import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.91538 2.3319C9.53953 1.79692 10.4605 1.79692 11.0847 2.3319L16.365 6.85786L18.4373 8.51571C18.7967 8.80322 18.8549 9.32763 18.5674 9.68701C18.2799 10.0464 17.7555 10.1047 17.3961 9.81716L16.6667 9.23362V15.8331C16.6667 16.7536 15.9205 17.4998 15 17.4998L11.6667 17.4998V13.3331C11.6667 12.4126 10.9205 11.6664 10 11.6664C9.07955 11.6664 8.33336 12.4126 8.33336 13.3331V17.4998L5.00003 17.4998C4.07956 17.4998 3.33336 16.7536 3.33336 15.8331V9.23362L2.60394 9.81716C2.24456 10.1047 1.72015 10.0464 1.43264 9.68701C1.14513 9.32763 1.2034 8.80322 1.56278 8.51571L3.6351 6.85786L8.91538 2.3319Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
