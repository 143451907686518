import API_PATHS from '@constants/apiPaths';
import { centralHttp } from '@http/index';
import { BaseResponseDto } from '@models/BaseResponse.dto';
import { UserEnrolledCourseStatuses } from '@models/course';
import { useCallback, useEffect, useMemo, useState } from 'react';

let statuses: UserEnrolledCourseStatuses | undefined = undefined;

export const useRetailStatus = ({ token }) => {
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    try {
      const res = await centralHttp.get<
        BaseResponseDto<UserEnrolledCourseStatuses>
      >(API_PATHS.USER_RETAIL_STATUS);
      if (res) {
        statuses = res.data.data;
      }
    } catch {
      statuses = undefined;
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (token && !isLoading && !statuses) {
      fetchData();
    }
  }, [token]);

  return {
    statuses,
    isLoading,
  };
};
