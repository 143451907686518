import { SVGProps } from 'react';

function SvgComponent(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.6859 3.88453C11.7334 2.17 9.26762 2.17 8.3151 3.88453L2.8143 13.786C1.88856 15.4523 3.09348 17.5001 4.99969 17.5001H16.0013C17.9075 17.5001 19.1124 15.4523 18.1867 13.786L12.6859 3.88453Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3337 7.49984C11.3337 7.0396 10.9606 6.6665 10.5003 6.6665C10.0401 6.6665 9.66699 7.0396 9.66699 7.49984V10.8332C9.66699 11.2934 10.0401 11.6665 10.5003 11.6665C10.9606 11.6665 11.3337 11.2934 11.3337 10.8332V7.49984ZM10.5003 14.9998C10.9606 14.9998 11.3337 14.6267 11.3337 14.1665C11.3337 13.7063 10.9606 13.3332 10.5003 13.3332C10.0401 13.3332 9.66699 13.7063 9.66699 14.1665C9.66699 14.6267 10.0401 14.9998 10.5003 14.9998Z"
        fill="white"
      />
    </svg>
  );
}

export default SvgComponent;
