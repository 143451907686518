import * as React from 'react';
import cx from 'classnames';

function SvgComponent(props) {
  const { className, ...otherProps } = props;
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cx('-ml-0.5', className)}
      {...otherProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.2929 1.31542C4.48044 1.11346 4.73479 1 5.00001 1H12C12.2652 1 12.5196 1.11346 12.7071 1.31542C12.8946 1.51739 13 1.79131 13 2.07692V4.4764C12.9991 4.64238 12.9627 4.80589 12.8938 4.95434C12.8249 5.10279 12.7252 5.23221 12.6025 5.33262L9.3287 8L12.6025 10.6674C12.7252 10.7678 12.8249 10.8972 12.8938 11.0457C12.9627 11.1941 12.999 11.3576 13 11.5236L13 11.5269V13.9231C13 14.2087 12.8946 14.4826 12.7071 14.6846C12.5196 14.8866 12.2652 15 12 15H5.00001C4.73479 15 4.48044 14.8866 4.2929 14.6846C4.10536 14.4826 4.00001 14.2087 4.00001 13.9231V11.5V11.4967C4.00096 11.3307 4.03731 11.1672 4.10623 11.0187C4.17516 10.8703 4.27482 10.7409 4.39755 10.6404L4.4 10.6385L7.66668 8L4.39754 5.35955C4.27482 5.25914 4.17516 5.12973 4.10623 4.98128C4.03731 4.8328 4.00097 4.66929 4.00002 4.50331L4 4.5L4.00001 2.07692C4.00001 1.79131 4.10537 1.51738 4.2929 1.31542Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
