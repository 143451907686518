import { SVGProps } from 'react';

function SvgComponent(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33325 2C2.22868 2 1.33325 2.89543 1.33325 4V9.33333C1.33325 10.4379 2.22868 11.3333 3.33325 11.3333H5.99992V12.6667H5.33325C4.96506 12.6667 4.66659 12.9651 4.66659 13.3333C4.66659 13.7015 4.96506 14 5.33325 14H10.6666C11.0348 14 11.3333 13.7015 11.3333 13.3333C11.3333 12.9651 11.0348 12.6667 10.6666 12.6667H9.99992V11.3333H12.6666C13.7712 11.3333 14.6666 10.4379 14.6666 9.33333V4C14.6666 2.89543 13.7712 2 12.6666 2H3.33325Z"
        fill="#B91E44"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00011 3.3335C6.52735 3.3335 5.33344 4.52741 5.33344 6.00017C5.33344 6.86427 5.74443 7.63238 6.38161 8.11968C5.25065 8.62536 4.44458 9.64809 4.44458 10.889C4.44458 11.1345 4.64357 11.3334 4.88903 11.3334H11.1113C11.3567 11.3334 11.5557 11.1345 11.5557 10.889C11.5557 9.64807 10.7496 8.62533 9.61864 8.11966C10.2558 7.63236 10.6668 6.86426 10.6668 6.00017C10.6668 4.52741 9.47287 3.3335 8.00011 3.3335Z"
        fill="#FE7C40"
      />
    </svg>
  );
}

export default SvgComponent;
