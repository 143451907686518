import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V8.82843C21 8.03278 20.6839 7.26972 20.1213 6.70711L16.2929 2.87868C15.7303 2.31607 14.9672 2 14.1716 2H6C4.34315 2 3 3.34315 3 5V19ZM13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12V15.5858L9.70711 14.2929C9.31658 13.9024 8.68342 13.9024 8.29289 14.2929C7.90237 14.6834 7.90237 15.3166 8.29289 15.7071L11.2929 18.7071C11.6834 19.0976 12.3166 19.0976 12.7071 18.7071L15.7071 15.7071C16.0976 15.3166 16.0976 14.6834 15.7071 14.2929C15.3166 13.9024 14.6834 13.9024 14.2929 14.2929L13 15.5858V12Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
