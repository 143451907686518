import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 4.66651C2 3.56194 2.89543 2.6665 4 2.6665L5.5963 2.6665C6.26501 2.6665 6.88947 3.00071 7.2604 3.5571L7.40627 3.7759C7.7772 4.3323 8.40166 4.6665 9.07037 4.6665L12 4.6665C13.1046 4.6665 14 5.56193 14 6.6665L14 11.3332C14 12.4377 13.1046 13.3332 12 13.3332L4 13.3332C2.89543 13.3332 2 12.4377 2 11.3332L2 4.66651Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
