import { parse } from 'cookie';
import { useRouter } from 'next/router';
import { Language } from '../models/language';

export const DEFAULT_LOCALE = 'th';

/**
 * Set Next.js locale cookie.
 * @param locale locale code (eg: en, th).
 */
export function setNextLocaleCookie(locale: string) {
  document.cookie = `NEXT_LOCALE=${locale}; max-age=31536000; path=/;`;
}

/**
 * Get prepend locale path.
 * @param locale locale code (eg: en, th)
 * @returns prepended locale path.
 */
export function getPrependLocale(locale: string) {
  return locale && locale !== DEFAULT_LOCALE ? `/${locale}` : '';
}

/**
 * Redirect to path and prepend with locale code.
 * @param locale locale code. (eg: en, th)
 * @param path path that we want to redirect.
 */
export function redirectToPathWithLocale(locale: string, path: string) {
  window.location.href = `${getPrependLocale(locale)}${path}`;
}

/**
 * Get current locale from cookie.
 * @returns current locale extract from cookie.
 */
export function getSelectedLang() {
  if (typeof window === 'object') {
    const l = parse(document.cookie);

    if (l && l.NEXT_LOCALE) return l.NEXT_LOCALE;
  }

  return DEFAULT_LOCALE;
}

/**
 * Get 2 leading characters of current locale. (eg: en, th)
 * @returns 2 leading characters of current locale.
 */
export function getSelectedLangTitle() {
  const locale = getSelectedLang();
  if (!locale || locale.length < 2) throw new Error('Invalid locale');

  return locale.substring(0, 2);
}

/**
 * Get 2 leading characters of current locale. (eg: En, Th)
 * @returns 2 leading characters of current locale.
 */
export function getLocaleTitle() {
  const locale = getSelectedLang();
  if (!locale || locale.length < 2) throw new Error('Invalid locale');

  return locale[0].toUpperCase() + locale[1];
}

/**
 * @deprecated Please use useLocaleText hook
 * Extract value for current locale from given language model
 * @param lang language model.
 * @returns String value from current locale.
 */
export function getLanguageValue(lang?: Language) {
  const locale = getLocaleTitle();
  return lang ? lang['name' + locale] || lang.nameEn || '' : '';
}
