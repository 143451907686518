import { UserEnrolledCourseStatuses } from '@models/course';
import { createContext, useContext } from 'react';

interface ITopNavBarContext {
  isLoading: boolean;
  statuses: UserEnrolledCourseStatuses | undefined;
}

export const TopNavBarContext = createContext<ITopNavBarContext | undefined>({
  isLoading: false,
  statuses: undefined,
});

export function useTopNavBar(): ITopNavBarContext {
  return useContext(TopNavBarContext);
}
