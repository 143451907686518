import { SVGProps } from 'react';

function SvgComponent(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.99967 0.333496C10.6817 0.333496 13.6663 3.31816 13.6663 7.00016C13.6663 10.6822 10.6817 13.6668 6.99967 13.6668C3.31767 13.6668 0.333008 10.6822 0.333008 7.00016H1.66634C1.66634 9.9455 4.05434 12.3335 6.99967 12.3335C9.94501 12.3335 12.333 9.9455 12.333 7.00016C12.333 4.05483 9.94501 1.66683 6.99967 1.66683C5.16634 1.66683 3.54901 2.5915 2.58967 4.00016H4.33301V5.3335H0.333008V1.3335H1.66634V3.00016C2.88234 1.38016 4.81901 0.333496 6.99967 0.333496ZM7.66634 3.66683V6.7235L9.82834 8.8855L8.88501 9.82883L6.33301 7.2755V3.66683H7.66634Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
